import { useEffect, useState } from 'react'
import { CheckboxIcon, ConfirmDialog, DialogHeader, theme } from '@avenue-8/platform-style-util-frontend'
import { generateDataAttrs, datadogRum } from '@avenue-8/platform-shared-util-frontend'
import styled from '@emotion/styled'
import {
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Tooltip,
  IconButton,
  MenuItem,
  Menu,
  ListItemIcon,
} from '@mui/material'
import { Control, Controller, FieldValues, useFormContext } from 'react-hook-form'
import ColoredBox from './colored-box'
import { TargetListDto } from '../../domain/target-lists.dto'
import { useEmailIntegrations } from '../../contexts/email-integrations.context'
import { TargetedListDetailsModal } from './targeted-list-details-modal'
import {
  Refresh as RefreshIcon,
  Clear as ClearIcon,
  Info as InfoIcon,
  MoreVert as MoreIcon,
  Warning as WarningIcon,
} from '@mui/icons-material'
import { TargetedListRefreshModal } from './target-list-refresh-modal'

type TargetedListBoxProps = {
  boxTitle: string
  name: string
  lists: TargetListDto[]
  onCreateButtonClick?: () => void
}

const CreateListButton = styled(Button)`
  letter-spacing: normal;
  text-transform: none;
  color: #8c8ea1;
  font-weight: 400;
  font-size: 14px;
  padding: 0.75rem 0.25rem;
`

export const AgentsCount = styled.span`
  font-size: 12px;
  font-weight: bold;
  margin-left: 0.3rem;
`

export const TargetedListBox = ({ boxTitle, name, lists, onCreateButtonClick }: TargetedListBoxProps): JSX.Element => {
  const { control } = useFormContext()
  const { deleteTargetList, service } = useEmailIntegrations()
  const [emailLists, setEmailLists] = useState(lists)
  const [open, setOpenModal] = useState(false)
  const [listToDelete, setListToDelete] = useState<TargetListDto>(null)
  const [dialogText, setDialogText] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const [isError, setIsError] = useState(false)
  const [detailModalState, setDetailModalState] = useState<{ targetList: TargetListDto; open: boolean } | null>(null)
  const [refreshModalState, setRefreshModalState] = useState<{ targetList: TargetListDto; open: boolean } | null>(null)

  useEffect(() => {
    setEmailLists(lists)
  }, [lists])

  const handleDeleteTargetListClick = (list: TargetListDto) => {
    const listName = listToDelete?.name ? `(${listToDelete.name})` : ''
    setDialogText(`Are you sure you would like to delete this Target List ${listName}?`)
    setListToDelete(list)
    setOpenModal(true)
  }

  const handleDetailTargetListClick = (list: TargetListDto) => {
    setDetailModalState({ targetList: list, open: true })
  }

  const handleCloseModalAfterError = () => {
    setOpenModal(false)
    setIsError(false)
  }

  const handleDeleteTargetListConfirmation = () => {
    if (deleteTargetList && listToDelete) {
      setIsProcessing(true)
      deleteTargetList(listToDelete.id)
        .then((deletedListId) => {
          setEmailLists(emailLists.filter((lst) => lst.id !== deletedListId))
          setOpenModal(false)
        })
        .catch((error) => {
          setIsError(true)
          datadogRum.addError(error)
          console.error(error)
          setDialogText('There was a error trying to delete your target list, please get in contact with Avenue 8 team')
        })
        .finally(() => setIsProcessing(false))
    }
  }

  const handleRefreshTargetedListClick = (list: TargetListDto) => {
    setRefreshModalState({ targetList: list, open: true })
  }

  const getRefreshPreview = async () => {
    const response = await service.refreshTargetedList({
      listId: refreshModalState?.targetList?.id,
      dryRun: true,
    })
    if (response.success === false) throw new Error('Failed to get refresh preview')
    return response.summary
  }

  const handleClose = () => {
    setRefreshModalState((value) => ({ ...value, open: false }))
  }

  const refresh = async () => {
    const response = await service.refreshTargetedList({
      listId: refreshModalState?.targetList?.id,
      dryRun: false,
    })
    if (!response.success) {
      throw new Error('Failed to refresh list')
    }
  }

  const loadEmails = async () => {
    const result = await service.getTargetedListEmails({ listId: detailModalState?.targetList?.id })
    if (result.success === false) throw new Error('Failed to get emails')
    return result.emails
  }

  return (
    <>
      <TargetedListDetailsModal
        listName={detailModalState?.targetList?.name}
        loadEmails={loadEmails}
        open={detailModalState?.open === true}
        onClose={() => setDetailModalState((value) => ({ ...value, open: false }))}
        lastRefresh={new Date(detailModalState?.targetList?.modifiedAt || 0)}
      />

      <TargetedListRefreshModal
        title={`Refresh Target List: ${refreshModalState?.targetList?.name}`}
        open={refreshModalState?.open === true}
        onClose={handleClose}
        getRefreshPreview={getRefreshPreview}
        refresh={refresh}
      />

      <ConfirmDialog
        onCancel={() => setOpenModal(false)}
        onConfirm={isError ? handleCloseModalAfterError : handleDeleteTargetListConfirmation}
        open={open}
        confirmLabel={isProcessing ? 'Processing' : 'Confirm'}
        cancelLabel='Cancel'
        dialogTitle={<DialogHeader title={'Target List'} subtitle={''} onCancel={() => setOpenModal(false)} />}
      >
        <Typography color='secondary' fontSize='14px'>
          {dialogText}
        </Typography>
      </ConfirmDialog>

      <TargetedListBoxFC
        boxTitle={boxTitle}
        name={name}
        onCreateButtonClick={onCreateButtonClick}
        onDeleteTargetedListClick={handleDeleteTargetListClick}
        // onDetailTargetedListClick={handleDetailTargetListClick}
        onRefreshTargetedListClick={handleRefreshTargetedListClick}
        emailLists={emailLists}
        control={control}
      />
    </>
  )
}

export const TargetedRow = styled.div`
  display: flex;
  max-width: 400px;
`
export const TargetedLabelCell = styled.div`
  flex: 1;
`
export const TargetedActionCell = styled.div`
  flex: 0;
`

export function TargetedListBoxFC({
  boxTitle,
  name,
  onCreateButtonClick,
  onDetailTargetedListClick: onDetailTargetListClick,
  onDeleteTargetedListClick: onDeleteTargetListClick,
  onRefreshTargetedListClick,
  emailLists,
  control,
}: {
  boxTitle: string
  name: string
  onCreateButtonClick?: () => void
  onDeleteTargetedListClick: (list: TargetListDto) => void
  onDetailTargetedListClick?: (list: TargetListDto) => void
  onRefreshTargetedListClick: (list: TargetListDto) => void
  emailLists: TargetListDto[]
  control: Control<FieldValues>
}): JSX.Element {
  const [menuState, setMenuState] = useState<{ list: TargetListDto; element: any } | null>(null)

  return (
    <ColoredBox color='grey' p='16px' pb='11px'>
      <Typography data-testid='targeted-list-box-title' color='secondary' fontSize='14px'>
        {boxTitle}&nbsp;
        <Tooltip title='The number next to your Target List is the number of contacts it contains. If you just created your list, it will display 0 contacts as they are still being processed. You can schedule your email even if it still displays 0.'>
          <IconButton size='small'>
            <InfoIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      </Typography>

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            {emailLists.map((list, index) => (
              <TargetedRow key={index}>
                <TargetedLabelCell>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={list.id}
                        name={list.id}
                        size='small'
                        data-testid={`target-list-checkbox-${list.id}`}
                        checked={field?.value?.some((existingValue) => existingValue === list.id)}
                        onBlur={field.onBlur}
                        onChange={(event, checked) => {
                          if (checked) {
                            field.onChange([...field.value, event.target.value])
                          } else {
                            field.onChange(field.value.filter((value) => value !== event.target.value))
                          }
                          field.onBlur()
                        }}
                        checkedIcon={<CheckboxIcon color={theme.palette.primary.main} backgroundColor='transparent' />}
                        icon={<CheckboxIcon color='transparent' backgroundColor='transparent' />}
                      />
                    }
                    label={
                      <Typography color='primary' fontSize='14px'>
                        {list.name} <AgentsCount>({list.count} Agents)</AgentsCount>
                        &nbsp;
                        {wasGenerated2MonthsAgo(new Date(list.modifiedAt)) && (
                          <Tooltip
                            title={
                              <>
                                Refresh recommended.
                                <br />
                                Last refresh: {timeAgo(new Date(list.modifiedAt))}
                              </>
                            }
                          >
                            <IconButton size='small'>
                              <WarningIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Typography>
                    }
                  />
                </TargetedLabelCell>
                <TargetedActionCell>
                  <IconButton
                    onClick={(e) => {
                      setMenuState({ list, element: e.currentTarget })
                    }}
                  >
                    <MoreIcon fontSize='small' />
                  </IconButton>
                </TargetedActionCell>
              </TargetedRow>
            ))}
          </>
        )}
      />

      <CreateListButton
        onClick={onCreateButtonClick}
        variant='text'
        {...generateDataAttrs({ metaAction: 'create-target-list', metaName: 'create-target-list' })}
      >
        + Create a new targeted list
      </CreateListButton>

      {menuState && (
        <Menu anchorEl={menuState.element} onClose={() => setMenuState(null)} open={!!menuState}>
          {onDetailTargetListClick && (
            <MenuItem
              onClick={() => {
                setMenuState(null)
                onDetailTargetListClick(menuState.list)
              }}
            >
              <ListItemIcon>
                <InfoIcon fontSize='small' />
              </ListItemIcon>
              <Typography variant='inherit'>Details</Typography>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              setMenuState(null)
              onRefreshTargetedListClick(menuState.list)
            }}
          >
            <ListItemIcon>
              <RefreshIcon fontSize='small' />
            </ListItemIcon>
            <Typography variant='inherit'>Refresh</Typography>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setMenuState(null)
              onDeleteTargetListClick(menuState.list)
            }}
          >
            <ListItemIcon>
              <ClearIcon fontSize='small' />
            </ListItemIcon>
            <Typography variant='inherit'>Delete</Typography>
          </MenuItem>
        </Menu>
      )}
    </ColoredBox>
  )
}

const wasGenerated2MonthsAgo = (date: Date): boolean => {
  const diff = Math.floor((new Date().getTime() - date.getTime()) / 1000)
  return diff > 5184000
}

const timeAgo = (date: Date): string => {
  // days, months, years
  const diff = Math.floor((new Date().getTime() - date.getTime()) / 1000)
  if (diff < 60) return 'a few seconds ago'
  if (diff < 3600) {
    const v = Math.floor(diff / 60)
    return v + ` minute${v === 1 ? '' : 's'} ago`
  }
  if (diff < 86400) {
    const v = Math.floor(diff / 3600)
    return v + ` hour${v === 1 ? '' : 's'} ago`
  }
  if (diff < 2592000) {
    const v = Math.floor(diff / 86400)
    return v + ` day${v === 1 ? '' : 's'} ago`
  }
  if (diff < 31536000) {
    const v = Math.floor(diff / 2592000)
    return v + ` month${v === 1 ? '' : 's'} ago`
  }
  const v = Math.floor(diff / 31536000)
  return v + ` year${v === 1 ? '' : 's'} ago`
}
