import { differenceInMilliseconds, hoursToMilliseconds, isPast, isValid, parse } from 'date-fns'
import * as Yup from 'yup'

const MAX_SEND_TIME_HOURS = 72
const maxAvailableDateDurationInMs = hoursToMilliseconds(MAX_SEND_TIME_HOURS)

const fieldMessageError = {
  subjectLine: {
    required: 'Enter a subject line',
    max: "This field shouldn't have more than 200 characters",
  },
  sendDate: {
    required: 'Select date',
    min: 'Date has already passed',
  },
  sendTime: {
    required: 'Select time',
    format: 'Invalid time format',
    min: 'Time has already passed',
    max: `Should be less than ${MAX_SEND_TIME_HOURS} hours`,
  },
  previewText: {
    required: 'Enter a preview text',
    max: "This field shouldn't have more than 100 characters",
  },
  emails: {
    required: 'Enter an email',
    email: 'Enter a valid email',
  },
}

// Makes the email required only if more than one email is provided and if no lists were selected.
Yup.addMethod(Yup.array, 'getValidationBasedOnArrayLength', function (errorMessage) {
  return this.test('getValidationBasedOnArrayLength', errorMessage, function (emails: Array<{ emailAddress: string }>) {
    const { mlsListsIds, soiListsIds, targetListsIds, csvFile } = this.parent

    const noListSelected =
      mlsListsIds.length === 0 && soiListsIds.length === 0 && targetListsIds.length === 0 && csvFile === null

    if (noListSelected) {
      if (emails.length > 1) {
        return Yup.array(
          Yup.object({
            emailAddress: Yup.string()
              .required(fieldMessageError.emails.required)
              .email(fieldMessageError.emails.email),
          })
        )
          .min(1)
          .isValid(emails)
      } else {
        return Yup.array(
          Yup.object({
            emailAddress: Yup.string().email(fieldMessageError.emails.email),
          })
        )
          .min(1)
          .isValid(emails)
      }
    } else {
      return Yup.array(
        Yup.object({
          emailAddress: Yup.string().email(fieldMessageError.emails.email),
        })
      ).isValid(emails)
    }
  })
})

// Checks if the string has the following format: 00:00 AM or 00:00 PM
Yup.addMethod(Yup.string, 'time12format', function (errorMessage) {
  return this.test('time12format', errorMessage, (sendTime: string) => {
    if (sendTime) {
      if (isNaN(new Date(sendTime).getTime())) {
        return /^\d{1,2}:\d{2}\s(AM|PM)$/.test(sendTime.trim())
      }
      return true
    }
    return true
  })
})

// Checks if sendTime has a time that already passed
Yup.addMethod(Yup.string, 'minAvailableDate', function (errorMessage) {
  return this.test('minAvailableDate', errorMessage, function (sendTime: string) {
    const { sendDate }: { sendDate: Date } = this.parent

    if (isValid(sendDate)) {
      if (!isValid(new Date(sendTime)) && sendTime) {
        const parsedData = parse(sendTime, 'hh:mm a', new Date())

        if (isValid(parsedData)) {
          const concatedDate = new Date(
            sendDate.getFullYear(),
            sendDate.getMonth(),
            sendDate.getDate(),
            parsedData.getHours(),
            parsedData.getMinutes()
          )
          return !isPast(concatedDate)
        } else {
          return false
        }
      } else {
        const sendTimeDate = new Date(sendTime)
        const concatedDate = new Date(
          sendDate.getFullYear(),
          sendDate.getMonth(),
          sendDate.getDate(),
          sendTimeDate.getHours(),
          sendTimeDate.getMinutes()
        )
        return !isPast(concatedDate)
      }
    } else {
      if (!isValid(new Date(sendTime)) && sendTime) {
        const parsedData = parse(sendTime, 'hh:mm a', new Date())

        if (isValid(parsedData)) {
          return !isPast(parsedData)
        } else {
          return false
        }
      }
      return true
    }
  })
})

Yup.addMethod(Yup.string, 'maxAvailableDate', function (errorMessage) {
  return this.test('maxAvailableDate', errorMessage, function (sendTime: string) {
    const today = new Date()

    const { sendDate }: { sendDate: Date } = this.parent
    const parsedSendDate = isValid(sendDate) ? (sendDate as Date) : today

    let parsedSendTime = new Date(sendTime)
    parsedSendTime = isValid(parsedSendTime) ? parsedSendTime : parse(sendTime as string, 'hh:mm a', new Date())

    const scheduledDate = new Date(
      parsedSendDate.getFullYear(),
      parsedSendDate.getMonth(),
      parsedSendDate.getDate(),
      parsedSendTime.getHours(),
      parsedSendTime.getMinutes()
    )

    return differenceInMilliseconds(scheduledDate, today) <= maxAvailableDateDurationInMs
  })
})

const yesterday = new Date(Date.now() - 86400000)

export const campaignDetailsSchema = Yup.object()
  .shape({
    subjectLine: Yup.string()
      .required(fieldMessageError.subjectLine.required)
      .max(200, fieldMessageError.subjectLine.max),

    sendDate: Yup.date()
      .min(yesterday, fieldMessageError.sendDate.min)
      .nullable()
      .typeError(fieldMessageError.sendDate.required),

    sendTime: Yup.string()
      .required(fieldMessageError.sendTime.required)
      .trim(fieldMessageError.sendTime.required)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .time12format(fieldMessageError.sendTime.format)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .minAvailableDate(fieldMessageError.sendTime.min)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .maxAvailableDate(fieldMessageError.sendTime.max),

    previewText: Yup.string()
      .required(fieldMessageError.previewText.required)
      .max(100, fieldMessageError.previewText.max),

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    emails: Yup.array().getValidationBasedOnArrayLength(),
    soiListsIds: Yup.array().of(Yup.string()),
    mlsListsIds: Yup.array().of(Yup.string()),
    targetListsIds: Yup.array().of(Yup.string()),
    csvFile: Yup.mixed(),
  })
  .test('at-least-one-send-email', 'At least one destination is required', (value) => {
    const { emails, csvFile, soiListsIds, mlsListsIds, targetListsIds } = value

    const validEmails = emails.filter((email) => email.emailAddress)

    return (
      validEmails.length > 0 || soiListsIds.length > 0 || mlsListsIds.length > 0 || targetListsIds.length > 0 || csvFile
    )
  })
