import format from 'date-fns/format'

// Input example: [["$1200", "    "], ["2BD", "2BA", " | "]]
// Output example: $1200    2BD | 2BA
export const generateComboLine = (arr: string[][]): string =>
  arr
    .map((curr) => {
      const separator = curr.slice(-1) as any
      const entries = curr.slice(0, -1).filter((c) => c)

      if (entries.length > 1) {
        return entries.join(separator)
      } else if (entries.length === 1) {
        if (curr.length === 2) {
          return entries[0] + separator
        } else {
          return entries[0]
        }
      } else {
        return null
      }
    })
    .join('')
    .trim()

export const toMoney = (value: string | number, fractionDigits = 0): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  })
  const numericValue = Number.isNaN(+value) ? 0 : +value
  return formatter.format(+numericValue)
}

export const formatBathCount = (input?: number | string): string => (input ? `${input}BA` : ``)
export const formatBedCount = (input?: number | string): string => (input ? `${input}BD` : ``)
export const formatDate = (date?: Date): string => format(date || new Date(), `LLLL dd 'at' hh':'mmaaa`)
