import * as Yup from 'yup'

export const MAX_TARGET_AREAS = 5

const targetAreaSchema = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string().required(),
  type: Yup.string().required(),
  suggestionData: Yup.object().required(),
})

export const targetListSchema = Yup.object().shape({
  name: Yup.string().min(1).max(100).required(),
  timeFrame: Yup.string()
    .oneOf(['last-30-days', 'last-60-days', 'last-3-months', 'last-6-months', 'last-12-months', 'last-2-years'])
    .required(),
  targetArea: Yup.array().min(1).max(MAX_TARGET_AREAS).of(targetAreaSchema).required(),
})
