import { generateDataAttrs, useFeatureFlags } from '@avenue-8/platform-shared-util-frontend'
import { CheckboxIcon, theme, Tooltip } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Typography, CircularProgress } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useEffect, useState, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { SOIList } from '../../domain/soi-list.dto'
import { MLSList } from '../../domain/mls-list.dto'
import ColoredBox from './colored-box'
import CsvUploader from './csv-uploader'
import MailingListBox from './mailing-list-box'
import { getFinalizeData } from '../utils/getFinalizeData'
import { FinalizeFormType } from '../../domain/finalize-form'
import { useEmailProjects } from '../../contexts/email-projects.context'
import { TargetedListBox } from './targeted-list-box'
import { useHistory } from 'react-router'
import { TargetListDto } from '../../domain/target-lists.dto'

const MailingSelectBox = styled(ColoredBox)`
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 188px;
`

const MLSDeprecationNoteBox = styled.div`
  display: flex;
  flex-direction: row;
`

type EmailListSharingFormProps = {
  soiLists: {
    data: SOIList[]
    loading: boolean
  }
  mlsLists: {
    data: MLSList[]
    loading: boolean
  }
  targetLists: {
    data: TargetListDto[]
    loading: boolean
  }
}

type MLSListDeprecationFlag = { warningMessage: string; tooltipMessage: string; enabled: boolean }

export default function EmailListSharingForm({
  soiLists,
  mlsLists,
  targetLists,
}: EmailListSharingFormProps): JSX.Element {
  const { hasFeature, getFeatureData } = useFeatureFlags()
  const [isTargetedListsEnabled, setIsTargetedListsEnabled] = useState(false)
  const [isMlsListsEnabled, setIsMlsListsEnabled] = useState(false)
  const [mlsListDeprecationNote, setMlsListDeprecationNote] = useState({ warningMessage: '', tooltipMessage: '' })
  const history = useHistory()

  useEffect(() => {
    hasFeature('targeted-list').then((isEnabled: boolean) => setIsTargetedListsEnabled(isEnabled))
    getFeatureData('mls-list').then(({ warningMessage, tooltipMessage, enabled }: MLSListDeprecationFlag) => {
      setIsMlsListsEnabled(enabled)
      if (!warningMessage || !tooltipMessage) return
      setMlsListDeprecationNote({ warningMessage, tooltipMessage })
    })
  }, [hasFeature])

  const { control, watch, setValue } = useFormContext()

  const { saveProject } = useEmailProjects()

  const watchCustomList = watch('customList')
  const watchMlsList = watch('mlsList')
  const watchSoiList = watch('soiList')
  const watchTargetList = watch('targetList')
  const isAnyCheckboxFieldChecked = [watchCustomList, watchMlsList, watchSoiList, watchTargetList].some(
    (field) => !!field
  )
  const data = watch()

  useEffect(() => {
    const finalizeData = getFinalizeData(data as FinalizeFormType)
    saveProject({ finalizeData })
  }, [data.soiListsIds, data.mlsListsIds, data.targetListsIds, data.soiList, data.mlsList, data.targetList])

  const cleanUpCustomList = useCallback(() => {
    setValue('csvFile', null, {
      shouldValidate: true,
    })
  }, [setValue])

  const cleanUpSOIList = useCallback(() => {
    setValue('soiListsIds', [], {
      shouldValidate: true,
    })
  }, [setValue])

  const cleanUpMLSList = useCallback(() => {
    setValue('mlsListsIds', [], {
      shouldValidate: true,
    })
  }, [setValue])

  const cleanTargetList = useCallback(() => {
    setValue('targetListsIds', [], {
      shouldValidate: true,
    })
  }, [setValue])

  // Clean up CSV file when custom list is unchecked
  useEffect(() => {
    if (!watchCustomList) {
      cleanUpCustomList()
    }
  }, [watchCustomList, cleanUpCustomList])

  // Clean up SOI list when sphere of influence is unchecked
  useEffect(() => {
    if (!watchSoiList) {
      cleanUpSOIList()
    }
  }, [watchSoiList, cleanUpSOIList])

  useEffect(() => {
    if (!watchMlsList) {
      cleanUpMLSList()
    }
  }, [watchMlsList, cleanUpMLSList])

  // Clean up Target lists unchecked
  useEffect(() => {
    if (!watchTargetList) {
      cleanTargetList()
    }
  }, [watchTargetList, cleanTargetList])

  return (
    <Box mt={2}>
      <Grid container>
        <Grid item lg={12} xl={10}>
          <FormGroup>
            <FormControlLabel
              control={
                <Controller
                  name='customList'
                  control={control}
                  render={({ field }) => {
                    const { ref, ...rest } = field
                    return (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        size='small'
                        checkedIcon={<CheckboxIcon color={theme.palette.primary.main} backgroundColor='transparent' />}
                        icon={<CheckboxIcon color='transparent' backgroundColor='transparent' />}
                        {...generateDataAttrs({
                          metaAction: `checkbox-list-sharing-form-custom-list`,
                        })}
                      />
                    )
                  }}
                />
              }
              label={
                <Typography color='primary' fontSize='14px'>
                  I’ll send to a custom list.
                </Typography>
              }
            />
            {isMlsListsEnabled ? (
              <FormControlLabel
                control={
                  <Controller
                    name='mlsList'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        disabled={!mlsLists?.data || mlsLists?.data?.length === 0 || mlsLists?.loading}
                        size='small'
                        checked={field.value}
                        checkedIcon={<CheckboxIcon color={theme.palette.primary.main} backgroundColor='transparent' />}
                        icon={<CheckboxIcon color='transparent' backgroundColor='transparent' />}
                        {...generateDataAttrs({
                          metaAction: `checkbox-list-sharing-form-mls-list`,
                        })}
                      />
                    )}
                  />
                }
                label={
                  <Typography color={mlsLists?.data?.length > 0 ? 'primary' : 'secondary'} fontSize='14px'>
                    I’ll send to a MLS list.
                    {` `}
                    {mlsLists?.loading ? (
                      <CircularProgress size={12} />
                    ) : (
                      <Tooltip
                        title={`${mlsListDeprecationNote.warningMessage} ${mlsListDeprecationNote.tooltipMessage}`}
                        titleStyles={{
                          fontWeight: 400,
                          fontSize: '12px',
                        }}
                        placement='right-end'
                      />
                    )}
                  </Typography>
                }
              />
            ) : null}
            <FormControlLabel
              control={
                <Controller
                  name='soiList'
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      size='small'
                      disabled={!soiLists?.data || soiLists?.data?.length === 0 || soiLists?.loading}
                      checkedIcon={<CheckboxIcon color={theme.palette.primary.main} backgroundColor='transparent' />}
                      icon={<CheckboxIcon color='transparent' backgroundColor='transparent' />}
                      {...generateDataAttrs({
                        metaAction: `checkbox-list-sharing-form-soi-list`,
                      })}
                    />
                  )}
                />
              }
              label={
                <Typography color={soiLists?.data?.length > 0 ? 'primary' : 'secondary'} fontSize='14px'>
                  I’ll send this to my sphere of influence list.
                  {` `}
                  {soiLists?.loading && <CircularProgress size={12} />}
                </Typography>
              }
            />
            {isTargetedListsEnabled ? (
              <FormControlLabel
                control={
                  <Controller
                    name='targetList'
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        size='small'
                        checkedIcon={<CheckboxIcon color={theme.palette.primary.main} backgroundColor='transparent' />}
                        icon={<CheckboxIcon color='transparent' backgroundColor='transparent' />}
                        {...generateDataAttrs({
                          metaAction: `checkbox-list-sharing-form-targeted-list`,
                        })}
                      />
                    )}
                  />
                }
                label={
                  <Typography color='primary' fontSize='14px' data-testid='targeted-list-checkbox-label'>
                    I’ll send to a targeted list.{' '}
                    <Tooltip
                      title='Target your email to agents who have had similar listings (price, size, neighborhood) over a specified period of time.'
                      titleStyles={{
                        fontWeight: 400,
                        fontSize: '12px',
                      }}
                      placement='right-end'
                    />
                  </Typography>
                }
              />
            ) : null}
          </FormGroup>
        </Grid>

        <Grid item ml={2} lg={10} xl={8} mt='20px'>
          {!isAnyCheckboxFieldChecked ? (
            <MailingSelectBox color='grey'>
              <Typography color='secondary'>Select a mailing list from the options above.</Typography>
            </MailingSelectBox>
          ) : null}

          {watchCustomList ? (
            <Box mb='30px'>
              <CsvUploader />
            </Box>
          ) : null}

          {watchMlsList ? (
            <Box mb='5px'>
              <MailingListBox
                boxTitle='Select which MLS lists you’d like to share to:'
                name='mlsListsIds'
                mailingList={mlsLists?.data}
                warningNote={
                  <MLSDeprecationNoteBox>
                    <Tooltip
                      icon={<InfoOutlinedIcon fontSize='small' htmlColor='red' style={{ padding: '0px 8px' }} />}
                      title={mlsListDeprecationNote.tooltipMessage}
                      titleStyles={{
                        fontWeight: 400,
                        fontSize: '12px',
                      }}
                      placement='top-start'
                    />
                    <Typography color='red' fontSize='14px'>
                      {mlsListDeprecationNote.warningMessage}
                    </Typography>
                  </MLSDeprecationNoteBox>
                }
              />
            </Box>
          ) : null}

          {watchSoiList && soiLists?.data?.length > 0 ? (
            <MailingListBox
              boxTitle='Select which SOI lists you’d like to share to:'
              name='soiListsIds'
              mailingList={soiLists?.data}
            />
          ) : null}

          {watchTargetList ? (
            <TargetedListBox
              boxTitle='Choose a targeted list:'
              name='targetListsIds'
              lists={targetLists.data}
              onCreateButtonClick={() => history.push('/target-list')}
            />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  )
}
