import styled from '@emotion/styled'
import { Box, Typography, keyframes } from '@mui/material'
import { SpinnerIcon } from '../icons/spinner.icon'

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Container = styled(Box)`
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(250, 250, 255, 0.98);
  animation: ${fadeInAnimation} 0.1s ease-out;

  h1 {
    margin-top: 16px;
  }

  svg {
    animation: ${rotateAnimation} 2s linear infinite;
  }
`

type OverlayLoadingProps = {
  title?: string
}

export const OverlayLoading = ({ title = 'Loading...' }: OverlayLoadingProps): JSX.Element => {
  return (
    <Container>
      <SpinnerIcon />
      <Typography variant='h1' fontSize={26}>
        {title}
      </Typography>
    </Container>
  )
}
