const isObject = (val: any) => val && typeof val === 'object' && !Array.isArray(val)

const addDelimiter = (a: string, b: string) => (a ? `${a}.${b}` : b)

type FieldData = {
  fieldName: string
  fieldValue: any
}

export const getFormFieldsToArray = (obj: Record<string, any> = {}, head = ''): FieldData[] => {
  return Object.entries(obj).reduce((product: FieldData[], [key, value]: [string, any]) => {
    const fullPath = addDelimiter(head, key)

    return isObject(value)
      ? product.concat(getFormFieldsToArray(value, fullPath))
      : product.concat({
          fieldName: fullPath,
          fieldValue: value,
        })
  }, [])
}
