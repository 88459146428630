import React from 'react'

export const SpinnerIcon: React.FC = () => (
  <svg width='129' height='129' viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity='0.5'>
      <path
        d='M112.204 21.7506L73.0091 60.9458L128.461 60.9458'
        stroke='#73738C'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M21.8136 112.204L61.0088 73.0089L61.0088 128.461'
        stroke='#73738C'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path d='M16.8312 107.22L56.0264 68.025L0.574546 68.025' stroke='#73738C' strokeWidth='2' strokeMiterlimit='10' />
      <path
        d='M107.222 16.8309L68.0265 56.0261L68.0265 0.574295'
        stroke='#73738C'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M68.0273 128.461L68.0273 73.0089L107.917 112.899'
        stroke='#73738C'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M16.8312 21.8124L56.0264 61.0076L0.574542 61.0076'
        stroke='#73738C'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M112.94 107.956L73.0091 68.0249L128.461 68.0249'
        stroke='#73738C'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M61.0088 0.574298L61.0088 56.0261L21.8136 16.8309'
        stroke='#73738C'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
    </g>
  </svg>
)
