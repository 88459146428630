import styled from '@emotion/styled'

const MobilePhoneScreen = styled.iframe`
  width: 340px;
  height: 600px;
  border: solid 8px #000;
  border-radius: 32px;
`

export const MobileSimulationIframe = ({ htmlContent }: { htmlContent: string }): JSX.Element => {
  return (
    <MobilePhoneScreen
      srcDoc={`
          <html>
            <head>
              <meta name="viewport" content="width=device-width, initial-scale=1">
              <style>
                html, body {
                  margin: 0;
                  padding: 0;
                  font-family: sans-serif;
                }
              </style>
            </head>
            <body>
              ${htmlContent}
            </body>
          </html>
        `}
    ></MobilePhoneScreen>
  )
}
