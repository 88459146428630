import { CloseIcon, InputLabel } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'
import { Box, Button, Grid, IconButton, TextField, Typography } from '@mui/material'
import { Controller, useFormContext, useFieldArray } from 'react-hook-form'
import { useEmailProjects } from '../../contexts/email-projects.context'
import { getFinalizeData } from '../utils/getFinalizeData'
import ColoredBox from './colored-box'
import { FinalizeFormType } from '../../domain/finalize-form'

const FormItem = styled(Grid)`
  margin-bottom: 1rem;
`

const EmailBox = styled(ColoredBox)`
  padding: 16px 16px 13px 16px;
`

const AddEmailButton = styled(Button)`
  text-transform: lowercase;
  font-weight: 400;
  color: #8c8ea1;
  letter-spacing: 0.7px;
  padding: 0;
  font-size: 14px;
  font-weight: 300;
`

export default function EmailSharingForm(): JSX.Element {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext()
  const {
    fields: emailFields,
    append,
    remove,
  } = useFieldArray({
    name: 'emails',
  })

  const data = watch()
  const { saveProject, currentProject: project } = useEmailProjects()

  const saveFinalizeData = () => {
    const finalizeData = {
      ...getFinalizeData(data as FinalizeFormType),
      csvFile: project?.finalize?.csvFile,
    }
    saveProject({ finalizeData })
  }

  const addEmailAddress = () => {
    append({ emailAddress: '' })
    saveFinalizeData()
  }

  const removeEmailAddress = (index) => remove(index)

  return (
    <Box ml={0} mt={2}>
      <Grid container>
        <Grid item lg={10} xl={8}>
          <EmailBox color='grey'>
            <Typography fontSize={14} color='secondary'>
              Type in the email adress you want to send to:
            </Typography>

            <Grid container ml={1} mt={1}>
              <FormItem item lg={8}>
                <InputLabel htmlFor='email1'>Email 1</InputLabel>
                <Controller
                  name='emails.0.emailAddress'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder='xxxx@avenue8.com'
                      variant='standard'
                      fullWidth
                      id='email1'
                      inputProps={{
                        sx: { fontSize: '14px' },
                      }}
                      error={!!errors?.emails?.[0]?.emailAddress?.message}
                      helperText={errors?.emails?.[0]?.emailAddress?.message}
                      onBlur={() => saveFinalizeData()}
                    />
                  )}
                />
              </FormItem>

              {emailFields.length > 1
                ? emailFields
                    .filter((field, index) => index !== 0)
                    .map((emailField, index) => (
                      <Grid container key={emailField.id}>
                        <FormItem item lg={8}>
                          <InputLabel htmlFor={`email${index + 2}`}>Email {index + 2}</InputLabel>
                          <Controller
                            name={`emails.${index + 1}.emailAddress`}
                            control={control}
                            render={({ field }) => {
                              const { ref, ...rest } = field
                              return (
                                <TextField
                                  {...rest}
                                  placeholder='xxxx@avenue8.com'
                                  variant='standard'
                                  fullWidth
                                  id={`email${index + 2}`}
                                  ref={ref}
                                  inputProps={{
                                    sx: { fontSize: '14px' },
                                  }}
                                  error={!!errors?.emails?.[index + 1]?.emailAddress?.message}
                                  helperText={errors?.emails?.[index + 1]?.emailAddress?.message}
                                  onBlur={() => saveFinalizeData()}
                                />
                              )
                            }}
                          />
                        </FormItem>
                        <Grid item lg={2} ml={3} sx={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton onClick={() => removeEmailAddress(index + 1)} data-testid='remove-email-button'>
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))
                : null}
            </Grid>

            <AddEmailButton
              variant='text'
              onClick={addEmailAddress}
              sx={{ ml: 1 }}
              disableRipple
              size='small'
              data-testid='add-email-button'
            >
              + add another email address
            </AddEmailButton>
          </EmailBox>
        </Grid>
      </Grid>
    </Box>
  )
}
