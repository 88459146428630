import { EmailTemplatesServicesImpl } from './email-templates.service.implementation'
import { EmailTemplatesServicesMockImpl } from './email-templates.service.mock.implementation'
import { EmailTemplatesService } from './email-templates.service.type'

export const EmailTemplatesServiceFactory = (token: string, agentId: string): EmailTemplatesService => {
  // if (process.env.REACT_APP_ENV === 'development') {
  //   return new EmailTemplatesServicesMockImpl()
  // }

  return new EmailTemplatesServicesImpl(agentId, token)
}
