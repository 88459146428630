import { TemplateTheme } from '../../domain/template-theme'

const hexColorRegexp = /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})/

function getDesignTheme(body: any): TemplateTheme {
  const theme: TemplateTheme = {
    core: {
      // Core are styles defined in body panel in unlayer's editor
      textColor: body.values.textColor,
      backgroundColor: body.values.backgroundColor,
      fontFamily: body.values.fontFamily.label,
      linkColor: body.values.linkStyle.linkColor,
      linkHoverColor: body.values.linkStyle.linkHoverColor,
      linkUnderline: body.values.linkStyle.linkUnderline,
      linkHoverUnderline: body.values.linkStyle.linkHoverUnderline,
    },
    colors: {
      inline: [],
      realtor: undefined,
      headline: undefined,
    },
    styles: {
      realtor: undefined,
    },
  }

  for (const row of body.rows) {
    if (!row.columns) continue

    for (const column of row.columns) {
      if (!column.contents) continue

      for (const content of column.contents) {
        if (content.type === 'custom' || content.type !== 'text') continue

        // Should grab inline color by its hex code
        const inlineColor = content.values.text.match(hexColorRegexp)
        if (!inlineColor) continue
        theme.colors.inline.push(inlineColor[0])

        if (!content.values.text.match(/realtor/i)) continue

        // Should grab the inline color by its hex code when the text is REALTOR (subject to changes)
        const realtorColor = content.values.text.match(hexColorRegexp)
        if (!realtorColor) continue
        theme.colors.realtor = realtorColor[0]
      }
    }
  }

  theme.colors.inline = [...new Set(theme.colors.inline)]
  theme.colors.headline = theme.colors.inline[0]

  return theme
}

// It adds the theme property to the custom widgets' values, being available to use
export function applyTheme(designData: any) {
  const newDesignData = { ...designData }
  const theme = getDesignTheme(designData.body)

  for (const row of newDesignData.body.rows) {
    if (!row.columns) continue

    for (const column of row.columns) {
      if (!column.contents) continue

      for (const content of column.contents) {
        if (content.type !== 'custom') continue
        content.values.theme = theme
      }
    }
  }

  return newDesignData
}

export function isThemeApplied(designData) {
  for (const row of designData.body.rows) {
    if (!row.columns) continue

    for (const column of row.columns) {
      if (!column.contents) continue

      for (const content of column.contents) {
        if (content.type !== 'custom') continue
        if (!content.values.theme) return false
      }
    }
  }

  return true
}
