import styled from '@emotion/styled'
import {
  Box,
  Button,
  Checkbox as MuiCheckbox,
  CheckboxProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FormProvider,
  useForm,
  UseFormStateReturn,
} from 'react-hook-form'
import { CheckboxIcon, CloseIcon, Input, InputLabel, theme } from '@avenue-8/platform-style-util-frontend'
import { MinMaxField } from './min-max-field'
import { FiltersFormFields, FiltersFormValues, PropertyStatus, PropertyTypes } from '../../../domain/target-lists.dto'
import { yupResolver } from '@hookform/resolvers/yup'
import { filtersModalSchema } from '../../schemas/filters-modal-schema'
import { useMemo, useState } from 'react'
import { getFormFieldsToArray } from '../../utils/getFormFieldsToArray'

const propertyTypesOptions: { label: string; value: PropertyTypes }[] = [
  { label: 'Condominium', value: 'Condominium' },
  { label: 'Manufactured Home ', value: 'Manufactured' },
  { label: 'Single Family Home', value: 'Single Family Home' },
  { label: 'Multi Unit 2-4', value: 'Multi-Unit' },
  { label: 'TIC', value: 'Tenancy in Common' },
  { label: 'Townhouse', value: 'Townhouse' },
  { label: 'Coop', value: 'Coop' },
  { label: 'Loft', value: 'Loft' },
  { label: 'Other', value: 'Other' },
]
const listingStatus: { label: string; value: PropertyStatus }[] = [
  { label: 'Sold', value: 'Sold' },
  { label: 'Active', value: 'Active' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Contingent', value: 'Contingent' },
]

const filtersDefaultValue = {
  propertyStatus: {
    Sold: false,
    Active: false,
    Pending: false,
    Contingent: false,
  },
  hasDoormanAttendance: null,
  propertyTypes: {
    Condominium: false,
    Manufactured: false,
    'Single Family Home': false,
    'Multi-Unit': false,
    'Tenancy in Common': false,
    Townhouse: false,
    Coop: false,
    Loft: false,
    Other: false,
  },
  price: {
    startAt: null,
    endAt: null,
  },
  numBedrooms: {
    startAt: null,
    endAt: null,
  },
  numBathrooms: {
    startAt: null,
    endAt: null,
  },
  parkingGarageSpaces: {
    startAt: null,
    endAt: null,
  },
  livingSpace: {
    startAt: null,
    endAt: null,
  },
  lotSize: {
    startAt: null,
    endAt: null,
  },
  yearBuilt: {
    startAt: null,
    endAt: null,
  },
}

interface FiltersModalProps {
  open: boolean
  onClose: () => void
  applyFilters: (filters: FiltersFormValues) => void
}

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  height: 100%;
`

const ContainerColumn = styled.div`
  width: calc(50% - 2rem);
`

const CloseButton = styled(Button)`
  position: absolute;
  right: 2rem;
  top: 2rem;
  padding: 0;
  min-width: unset;
`

export default function FiltersModal({ open, onClose, applyFilters }: FiltersModalProps): JSX.Element {
  const resolver = yupResolver(filtersModalSchema)
  const formMethods = useForm<FiltersFormValues>({
    reValidateMode: 'onSubmit',
    defaultValues: filtersDefaultValue,
    resolver,
  })
  const { control, handleSubmit, reset, setValue } = formMethods

  const [lastSavedFilters, setLastSavedFilters] = useState<FiltersFormValues>(null)

  const clearFilters = () => {
    reset()
  }

  const discardChanges = () => {
    if (lastSavedFilters) {
      getFormFieldsToArray(lastSavedFilters).forEach(({ fieldName, fieldValue }) =>
        setValue(fieldName as FiltersFormFields, fieldValue)
      )
    }
    onClose()
  }

  const submitFilters = (filters: FiltersFormValues) => {
    setLastSavedFilters(JSON.parse(JSON.stringify(filters)))
    applyFilters(filters)
  }

  return (
    <FormProvider {...formMethods}>
      <Dialog
        open={open}
        fullWidth
        maxWidth={'lg'}
        scroll={'paper'}
        BackdropProps={{
          unmountOnExit: true,
        }}
        onClose={discardChanges}
      >
        <DialogContent sx={{ py: 2, px: 4, position: 'relative' }}>
          <DialogTitle sx={{ fontSize: '24px', px: 0, letterSpacing: '.5px' }}>Filters</DialogTitle>
          <CloseButton variant='text' onClick={discardChanges} data-testid='close-filters-modal-button'>
            <CloseIcon />
          </CloseButton>

          <Form id='filters-modal-form' onSubmit={handleSubmit(submitFilters)}>
            <ContainerColumn>
              {propertyTypesOptions.length > 0 ? (
                <>
                  <Typography fontWeight='bold' sx={{ my: 1 }}>
                    Property Types
                  </Typography>
                  <FormGroup sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                    {propertyTypesOptions.map(({ label, value }, i) => (
                      <FormControlLabel
                        key={value}
                        control={
                          <Controller
                            name={`propertyTypes.${value}`}
                            control={control}
                            render={(props) => <Checkbox dataTestId={`${value}-checkbox`} {...props} />}
                          />
                        }
                        label={<Label text={label} />}
                      />
                    ))}
                  </FormGroup>
                </>
              ) : null}

              <Typography fontWeight='bold' sx={{ my: 2 }}>
                Misc. Property Details
              </Typography>
              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '48px', mb: 3 }}>
                <FormControl>
                  <InputLabel>Doorman/attendance</InputLabel>
                  <Controller
                    control={control}
                    name='hasDoormanAttendance'
                    render={({ field: { ref, ...fieldRest } }) => {
                      return (
                        <Select input={<Input />} {...fieldRest}>
                          <MenuItem>&nbsp;</MenuItem>
                          <MenuItem value='yes'>Yes</MenuItem>
                          <MenuItem value='no'>No</MenuItem>
                        </Select>
                      )
                    }}
                  />
                </FormControl>
              </Box>
            </ContainerColumn>

            <Box sx={{ background: '#f2f2f2', width: '1px' }} />

            <ContainerColumn>
              <Typography fontWeight='bold' sx={{ my: 1 }}>
                Price
              </Typography>
              <MinMaxField fieldName='price' fieldProps={{ thousandSeparator: true }} />

              <Typography fontWeight='bold' sx={{ my: 1 }}>
                Beds
              </Typography>
              <MinMaxField fieldName='numBedrooms' fieldProps={{}} />

              <Typography fontWeight='bold' sx={{ my: 1 }}>
                Baths
              </Typography>
              <MinMaxField fieldName='numBathrooms' fieldProps={{}} />

              <Typography fontWeight='bold' sx={{ my: 1 }}>
                Garage Space(s)
              </Typography>
              <MinMaxField fieldName='parkingGarageSpaces' fieldProps={{ thousandSeparator: true }} />

              <Typography fontWeight='bold' sx={{ my: 1 }}>
                Living Space
              </Typography>
              <MinMaxField
                fieldName='livingSpace'
                fieldLabels={['Minimum Sq Ft', 'Maximum Sq Ft']}
                fieldProps={{ thousandSeparator: true }}
              />

              <Typography fontWeight='bold' sx={{ my: 1 }}>
                Lot Size
              </Typography>
              <MinMaxField
                fieldName='lotSize'
                fieldLabels={['Minimum Sq Ft', 'Maximum Sq Ft']}
                fieldProps={{ thousandSeparator: true }}
              />

              <Typography fontWeight='bold' sx={{ my: 1 }}>
                Year built
              </Typography>
              <MinMaxField fieldName='yearBuilt' />
            </ContainerColumn>
          </Form>
        </DialogContent>

        <DialogActions sx={{ pt: 1 }}>
          <Button onClick={clearFilters} data-testid='clear-filters-button'>
            Clear filters
            <CloseIcon />
          </Button>
          <Button type='submit' form='filters-modal-form' data-testid='apply-filters-button'>
            Apply filters
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  )
}

const Label = ({ text }: { text: string }): JSX.Element => (
  <Typography color='primary' fontSize='14px'>
    {text}
  </Typography>
)

// eslint-disable-next-line react/prop-types
const Checkbox = ({ field: { ref, ...fieldRest }, dataTestId }: Record<string, any>) => (
  <MuiCheckbox
    {...fieldRest}
    checked={fieldRest.value}
    size='small'
    checkedIcon={<CheckboxIcon color={theme.palette.primary.main} backgroundColor='transparent' />}
    icon={<CheckboxIcon color='transparent' backgroundColor='transparent' />}
    inputProps={{ 'data-testid': dataTestId } as React.InputHTMLAttributes<HTMLInputElement>}
  />
)
