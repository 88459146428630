import { PROPERTY_SEARCH_API_URL } from '@avenue-8/platform-shared-util-frontend'

type SearchAreaParams = {
  search?: string
  filters?: {
    address?: boolean
    neighborhood?: boolean
    area?: boolean
    city?: boolean
    zipCode?: boolean
    mlsId?: boolean
    county?: boolean
  }
}

export async function searchArea({ search, filters }: SearchAreaParams): Promise<any> {
  const size = 10
  try {
    const filterQueries = Object.entries(filters || {})
      .map(([key, value]) => `&${key}=${!!value}`)
      .join('')
    const res = await fetch(
      `${PROPERTY_SEARCH_API_URL}/api/v1/suggestions?text=${search || 'a'}&size=${size}` + filterQueries
    )

    const results = await res.json()

    return {
      success: true,
      data: results,
    }
  } catch (err) {
    return {
      success: false,
    }
  }
}
