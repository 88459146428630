import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'
import { useState } from 'react'
import styled from '@emotion/styled'
import { generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'

type EditButtonProps = {
  isEditing?: boolean
  hasSaved?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const StyledButton = styled(Button)`
  color: #73738c;

  &:disabled {
    opacity: 1;
    color: green;
  }
`

export const EditButton = ({ isEditing = false, hasSaved = false, onClick }: EditButtonProps): JSX.Element => {
  const title = hasSaved ? 'Saved' : isEditing ? 'Save' : 'Edit'

  return (
    <StyledButton
      variant='text'
      size='small'
      startIcon={!isEditing && !hasSaved && <EditIcon />}
      disabled={hasSaved}
      onClick={onClick}
      data-testid='edit-button'
      {...generateDataAttrs({
        metaAction: `edit-email-project-title`,
      })}
    >
      {title}
    </StyledButton>
  )
}
