import { useAuthContext } from '@avenue-8/platform-shared-util-frontend'
import { createContext, FC, useContext, useEffect, useState } from 'react'
import { EmailProjectDto } from '../domain/email-project.dto'
import { EmailProjectsServiceFactory } from '../shared/services/email-projects/email-projects.service.factory'
import { CreateEmailProjectDto } from '../shared/services/email-projects/email-projects.service.type'

const service = EmailProjectsServiceFactory()

export type EmailProjectsContextValue = {
  projects?: EmailProjectDto[]
  isProjectsRequestLoading?: boolean
  saveProject?: (data: any) => Promise<void>
  changeProjectTitle?: (newTitle: string) => Promise<void>
  newProject?: (body: CreateEmailProjectDto) => Promise<EmailProjectDto>
  setOrderId?: (orderId: string) => void
  updateJSON?: (json: string) => void
  updateHTML?: (html: string) => Promise<void>
  updateCover?: (newCoverUrl: string) => Promise<void>
  getProject?: (id: number) => Promise<EmailProjectDto | undefined>
  isServiceReady?: boolean
  deleteProject?: (project: EmailProjectDto) => Promise<void>
  currentProject?: EmailProjectDto
  loadProject?: (id: number) => Promise<void>
  cloneProject?: (project: EmailProjectDto) => Promise<EmailProjectDto>
}

export const useEmailProjects: () => EmailProjectsContextValue = () => useContext(EmailProjectsContext)

export const EmailProjectsContext = createContext<EmailProjectsContextValue>({})

export const EmailProjectsContextProvider: FC = (props) => {
  const { agentId, token } = useAuthContext()

  const [projects, setProjects] = useState<EmailProjectDto[]>()
  const [currentProject, setCurrentProject] = useState<EmailProjectDto>(null)

  const [isServiceReady, setIsServiceReady] = useState(false)
  const [isProjectsRequestLoading, setIsProjectsRequestLoading] = useState(true)

  useEffect(() => {
    if (agentId && token) {
      setIsServiceReady(true)
    }
  }, [agentId, token])

  useEffect(() => {
    if (!isServiceReady) return

    service.getProjects().then((res) => {
      setIsProjectsRequestLoading(false)
      if (!res.success) return
      setProjects(res.data)
    })
  }, [isServiceReady])

  async function loadProject(id: number) {
    const res = await service.getProjectById(id)

    if (!res.success) throw new Error('Project not found')

    setCurrentProject(res.data)
  }

  async function newProject({ templateId }: CreateEmailProjectDto) {
    const res = await service.createProject({ templateId })
    if (!res.success) return undefined

    const { data: newProject } = res
    setProjects([newProject, ...projects])

    setCurrentProject(newProject)
    return newProject
  }

  async function saveProject(data: any) {
    const payload = { ...data, html: undefined }

    const res = await service.updateProject(currentProject.id, payload)
    if (!res.success) throw new Error('Project not saved')

    const { data: savedProject } = res
    savedProject.rawJSON = data?.designData || currentProject.rawJSON
    savedProject.html = data?.html || currentProject.html
    savedProject.finalize = data?.finalizeData || currentProject.finalize

    setProjects((currentProjects) => currentProjects.map((p) => (p.id === currentProject.id ? savedProject : p)))

    setCurrentProject(savedProject)
  }

  async function changeProjectTitle(newTitle: string) {
    const res = await service.updateProject(currentProject.id, { title: newTitle })
    if (!res.success) throw new Error('Title not updated')

    const { data: updatedProject } = res

    setProjects((currentProjects) =>
      currentProjects.map((p) =>
        p.id === currentProject.id ? { ...currentProject, ...updatedProject, updatedAt: new Date() } : p
      )
    )

    return setCurrentProject({ ...currentProject, ...updatedProject, updatedAt: new Date() })
  }

  async function updateHTML(html: string) {
    const updatedProject = { ...currentProject, html }
    setProjects((currentProjects) => currentProjects.map((p) => (p.id === currentProject.id ? updatedProject : p)))

    setCurrentProject(updatedProject)
  }

  function setOrderId(orderId: string) {
    setCurrentProject({ ...currentProject, orderId })

    if (projects) {
      setProjects((currentProjects) =>
        currentProjects.map((p) => (p.id === currentProject.id ? { ...p, orderId, updatedAt: new Date() } : p))
      )
    }
  }

  async function getProject(id: number) {
    const res = await service.getProjectById(id)
    if (!res.success) return undefined
    const project = res.data

    return project
  }

  async function updateCover(newCoverUrl: string) {
    const updatedProject = { ...currentProject, cover: newCoverUrl }

    setProjects(projects.map((p) => (p.id === currentProject.id ? updatedProject : p)))
    service.updateProject(currentProject.id, { cover: newCoverUrl })

    setCurrentProject(updatedProject)
  }

  async function deleteProject(project: EmailProjectDto) {
    const { success } = await service.deleteProject(project.id)
    if (!success) return undefined
    setProjects(projects.filter((p) => p.id !== project.id))
  }

  async function cloneProject(project: EmailProjectDto) {
    const res = await service.cloneProject(project.id)
    if (!res.success) return undefined

    const { data: clonedProject } = res
    setProjects([clonedProject, ...projects])

    setCurrentProject(clonedProject)
    return clonedProject
  }

  return (
    <EmailProjectsContext.Provider
      value={{
        projects,
        isProjectsRequestLoading,
        saveProject,
        changeProjectTitle,
        newProject,
        setOrderId,
        updateHTML,
        getProject,
        updateCover,
        isServiceReady,
        deleteProject,
        currentProject,
        loadProject,
        cloneProject,
      }}
    >
      {props.children}
    </EmailProjectsContext.Provider>
  )
}
