import * as Yup from 'yup'

const fieldMessageError = {
  positive: 'Enter a positive number',
  notLessThan: 'Minimum must be lower than Maximum',
  notMoreThan: 'Minimum must be lower than Maximum',
}

const numberParse = (value: string | null): number => (value === '' ? NaN : +value?.replace(/,/g, ''))

const isPositive = (value: string | null): boolean => (value === null ? true : +value > 0)

const isMoreThanStart = function (value: string | null): boolean {
  if (value !== null) {
    const endAtValue = numberParse(this.resolve(Yup.ref('startAt')) as string | null) || 0
    return +value >= endAtValue
  } else return true
}

const isLessThanEnd = function (value: string | null): boolean {
  if (value !== null) {
    const endAtValue = numberParse(this.resolve(Yup.ref('endAt')) as string | null) || Infinity
    return +value <= endAtValue
  } else return true
}

const transformFinalValue = (value: number): string | null => (isNaN(+value) ? null : value + '')

const getLessStringSchema = (): Yup.StringSchema => {
  return Yup.string()
    .transform(numberParse)
    .test('isPositive', fieldMessageError.positive, isPositive)
    .test('isLess', fieldMessageError.notLessThan, isLessThanEnd)
    .transform(transformFinalValue)
    .nullable()
}

const getMoreStringSchema = (): Yup.StringSchema => {
  return Yup.string()
    .transform(numberParse)
    .test('isPositive', fieldMessageError.positive, isPositive)
    .test('isMore', fieldMessageError.notMoreThan, isMoreThanStart)
    .transform(transformFinalValue)
    .nullable()
}

export const filtersModalSchema = Yup.object().shape({
  price: Yup.object().shape({
    startAt: getLessStringSchema(),
    endAt: getMoreStringSchema(),
  }),
  numBedrooms: Yup.object().shape({
    startAt: getLessStringSchema(),
    endAt: getMoreStringSchema(),
  }),
  numBathrooms: Yup.object().shape({
    startAt: getLessStringSchema(),
    endAt: getMoreStringSchema(),
  }),
  parkingGarageSpaces: Yup.object().shape({
    startAt: getLessStringSchema(),
    endAt: getMoreStringSchema(),
  }),
  livingSpace: Yup.object().shape({
    startAt: getLessStringSchema(),
    endAt: getMoreStringSchema(),
  }),
  lotSize: Yup.object().shape({
    startAt: getLessStringSchema(),
    endAt: getMoreStringSchema(),
  }),
  yearBuilt: Yup.object().shape({
    startAt: getLessStringSchema(),
    endAt: getMoreStringSchema(),
  }),
})
