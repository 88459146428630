import { createContext, FC, useContext, useState } from 'react'
import { OverlayLoading } from '../shared/components/overlay-loading'

export type OverlayLoadingContextValue = {
  loading: boolean
  loadingTitle?: string
  setLoadingTitle?: (newTitle: string) => void
  showLoading?: (title?: string) => void
  hideLoading?: () => void
  toggleLoading?: () => void
}

export const useOverlayLoading: () => OverlayLoadingContextValue = () => useContext(OverlayLoadingContext)

export const OverlayLoadingContext = createContext<OverlayLoadingContextValue>({
  loading: false,
})

export const OverlayLoadingContextProvider: FC = (props) => {
  const [loading, setLoading] = useState(false)
  const [loadingTitle, setLoadingTitle] = useState('')

  function showLoading(title?: string) {
    setLoading(true)
    setLoadingTitle(title || '')
  }

  function hideLoading() {
    setLoading(false)
  }

  function toggleLoading() {
    setLoading(!loading)
  }

  return (
    <OverlayLoadingContext.Provider
      value={{
        loading,
        loadingTitle,
        hideLoading,
        showLoading,
        toggleLoading,
        setLoadingTitle,
      }}
    >
      {loading && <OverlayLoading title={loadingTitle} />}
      {props.children}
    </OverlayLoadingContext.Provider>
  )
}
