import { AuthedFetch, ResponseType, VISUAL_MARKETING_API_URL } from '@avenue-8/platform-shared-util-frontend'
import { EmailSubmitParamsDTO, EmailSubmitResponseDTO } from '../../../domain/email-submit.dto'
import { MLSList } from '../../../domain/mls-list.dto'
import { SendPreviewParamsDto } from '../../../domain/send-preview.dto'
import { SOIList } from '../../../domain/soi-list.dto'
import { PostTargetListDTO, TargetListDto } from '../../../domain/target-lists.dto'
import { EmailIntegrationsService } from './email-integrations.service.type'
import { UploadCSVResponseType } from './types/upload-csv-response.type'
import { TargetedListRefreshSummaryDto } from '../../../domain/refresh-targeted-list.dto'

export class EmailIntegrationsServicesImpl implements EmailIntegrationsService {
  baseUrl: string
  agentId: string
  token: string
  contentGridInstance: any

  constructor(agentId: string, token: string) {
    this.baseUrl = VISUAL_MARKETING_API_URL
    this.agentId = agentId
    this.token = token
  }

  async submit(params: EmailSubmitParamsDTO): Promise<ResponseType<EmailSubmitResponseDTO>> {
    // Submit email request to API
    const res = await AuthedFetch(`${this.baseUrl}/v2/email`, {
      body: JSON.stringify({ ...params, agentId: this.agentId }), // Append agentId to request body, so the previous layers doesn't need to know about it
      method: 'POST',
    })

    if (!res.ok) {
      return {
        success: false,
      }
    }

    const data = (await res.json()) as EmailSubmitResponseDTO

    return {
      data,
      success: true,
    }
  }

  async getSOIList(): Promise<ResponseType<SOIList[]>> {
    // Fetch SOI list from API
    const res = await AuthedFetch(`${this.baseUrl}/v2/email/lists/soi`)
    const data = (await res.json()) as SOIList[]

    return {
      data,
      success: true,
    }
  }

  async getMLSList(): Promise<ResponseType<MLSList[]>> {
    // Fetch SOI list from API
    const res = await AuthedFetch(`${this.baseUrl}/v2/email/lists/mls`)
    const data = (await res.json()) as MLSList[]

    return {
      data,
      success: true,
    }
  }

  async postTargetList(params: PostTargetListDTO): Promise<ResponseType<TargetListDto>> {
    try {
      const url = `${this.baseUrl}/v2/email/lists/targeted`

      const response = await AuthedFetch(url, {
        headers: new Headers({ 'Content-Type': 'application/json' }),
        method: 'post',
        body: JSON.stringify(params),
      })

      if (response.ok) {
        const data = await response.json()
        return { success: true, data }
      }
      return { success: false }
    } catch (err) {
      console.error('Error posting target list', err)
      return { success: false }
    }
  }

  async deleteTargetList(id: string): Promise<ResponseType<string>> {
    try {
      const url = `${this.baseUrl}/v2/email/lists/${id}`

      const response = await AuthedFetch(url, {
        headers: new Headers({ 'Content-Type': 'application/json' }),
        method: 'delete',
      })

      if (response.ok) {
        return response.json()
      }
      return { success: false }
    } catch (err) {
      console.error('Error posting target list', err)
      return { success: false }
    }
  }

  async getTargetLists(): Promise<ResponseType<TargetListDto[]>> {
    try {
      const url = `${this.baseUrl}/v2/email/lists/targeted`
      const response = await AuthedFetch(url)

      if (response.ok) {
        const data = (await response.json()) as TargetListDto[]
        return { success: true, data }
      }

      return { success: false }
    } catch (err) {
      console.error('Error fetching target lists', err)
      return { success: false }
    }
  }

  async uploadCSVFileAsList(file: File): Promise<ResponseType<string[]>> {
    try {
      // Prepare form data
      const formData = new FormData()
      formData.append('file', file)

      const params = new URLSearchParams({ agentId: this.agentId })

      // Upload file to API and get list of uploaded emails using fetch
      const res = await fetch(`${this.baseUrl}/v2/email/lists/csv?${params}`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })

      if (!res.ok) {
        return {
          success: false,
        }
      }

      const data = (await res.json()) as UploadCSVResponseType

      return {
        data: data.listIds,
        success: true,
      }
    } catch (error) {
      return {
        success: false,
      }
    }
  }

  async sendPreview(params: SendPreviewParamsDto): Promise<{ success: boolean }> {
    const res = await AuthedFetch(`${this.baseUrl}/v2/email/preview`, {
      body: JSON.stringify({ html: params.html, previewEmails: params.previewEmails, agentId: this.agentId }), // Append agentId to request body, so the previous layers doesn't need to know about it
      method: 'POST',
    })

    return {
      success: res.ok,
    }
  }

  async getTargetedListEmails(params: { listId: string }): Promise<{ success: boolean; emails?: string[] }> {
    const res = await AuthedFetch(
      `${this.baseUrl}/v2/email/lists/targeted/${params.listId}/emails?agentId=${this.agentId}`,
      {
        method: 'POST',
      }
    )

    if (!res.ok) {
      return {
        success: false,
      }
    }

    const data = await res.json()
    return {
      success: true,
      emails: data.emails,
    }
  }

  async refreshTargetedList(params: {
    listId: string
    dryRun: boolean
  }): Promise<{ success: boolean; summary?: TargetedListRefreshSummaryDto }> {
    const res = await AuthedFetch(
      `${this.baseUrl}/v2/email/lists/targeted/${params.listId}/refresh?agentId=${this.agentId}&dryRun=${params.dryRun}`,
      {
        method: 'POST',
      }
    )
    if (!res.ok) {
      return {
        success: false,
      }
    }
    const data = await res.json()
    return {
      success: true,
      summary: data,
    }
  }
}
