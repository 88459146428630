import { UnlayerDesignData } from './types'

export const findHeadline = (designData: UnlayerDesignData): string | undefined => {
  let firstTextFound: string

  for (const row of designData.body.rows) {
    if (firstTextFound) break

    for (const col of row.columns) {
      if (firstTextFound) break

      for (const content of col.contents) {
        if (content.type !== 'text') continue

        firstTextFound = content.values?.text
        break
      }
    }
  }

  if (!firstTextFound) return undefined

  const el = document.createElement('div')
  el.innerHTML = firstTextFound

  return el.innerText
}

// The cover will be the first image found
export const findCover = (designData: UnlayerDesignData): string | undefined => {
  let firstImageFound: string

  for (const row of designData.body.rows) {
    if (firstImageFound) break

    for (const col of row.columns) {
      if (firstImageFound) break

      for (const content of col.contents) {
        if (content.type !== 'image') continue

        firstImageFound = content.values?.src?.url
        break
      }
    }
  }

  return firstImageFound
}

export const debounce = (func: (...args: any[]) => any, wait: number): ((...args: any[]) => void) => {
  let timeout: any
  return (...args: any[]) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), wait)
  }
}
