import { Grid, Box, Autocomplete, TextField } from '@mui/material'
import styled from '@emotion/styled'
import { Controller, useFormContext } from 'react-hook-form'
import { Datepicker, Textarea, InputLabel, Tooltip, VerticalChevronIcon } from '@avenue-8/platform-style-util-frontend'
import { useMemo, useEffect } from 'react'
import { TimeOption } from '../utils/getTimeOptionsArray'
import { useEmailProjects } from '../../contexts/email-projects.context'
import { getFinalizeData } from '../utils/getFinalizeData'
import { FinalizeFormType } from '../../domain/finalize-form'
import { format, isValid, parseISO } from 'date-fns'

const FormItem = styled(Grid)`
  margin-bottom: 1rem;
`

const TimeAutocomplete = styled(Autocomplete)`
  & .MuiAutocomplete-endAdornment {
    right: 8px;
  }
`

const TimeInput = styled(TextField)`
  && input {
    padding-top: 5px !important;
    padding-bottom: 10px !important;
  }
`

type EmailDetailsFormProps = {
  timeOptions: TimeOption[]
}

export default function EmailDetailsForm({ timeOptions }: EmailDetailsFormProps): JSX.Element {
  const { saveProject, currentProject: project } = useEmailProjects()
  const {
    control,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext()
  const data = watch()
  const watchSendDateField = watch('sendDate')
  const watchSendTimeField: Date | string | undefined = watch('sendTime')

  const timeValueLabel = useMemo(() => {
    if (!watchSendTimeField || watchSendTimeField === '') return ''

    if (!(watchSendTimeField instanceof Date)) {
      const parsedString = parseISO(watchSendTimeField as string)
      return isValid(parsedString) ? format(parsedString, 'p') : watchSendTimeField
    }

    return format(watchSendTimeField, 'p')
  }, [watchSendTimeField])

  const saveFinalizeData = () => {
    const finalizeData = {
      ...getFinalizeData(data as FinalizeFormType),
      csvFile: project?.finalize?.csvFile,
    }
    saveProject({ finalizeData })
  }

  useEffect(() => {
    if (watchSendDateField && watchSendTimeField) {
      trigger('sendTime')
    }
  }, [watchSendDateField, watchSendTimeField, trigger])

  return (
    <Box ml={2} mt={2}>
      <Grid container columnSpacing={2}>
        <FormItem item lg={8} xl={6}>
          <InputLabel htmlFor='subjectLine'>Subject Line</InputLabel>
          <Controller
            name='subjectLine'
            control={control}
            render={({ field }) => {
              const { ref, ...rest } = field
              return (
                <TextField
                  {...rest}
                  placeholder='Enter a subject line for your email'
                  variant='standard'
                  fullWidth
                  inputRef={ref}
                  onBlur={() => saveFinalizeData()}
                  inputProps={{
                    sx: { fontSize: '14px !important' },
                  }}
                  error={!!errors.subjectLine?.message}
                  helperText={errors.subjectLine?.message}
                />
              )
            }}
          />
        </FormItem>
      </Grid>

      <Grid container columnSpacing={2}>
        <FormItem item lg={4} xl={3}>
          <InputLabel htmlFor='sendDate'>Send Date</InputLabel>
          <Controller
            name='sendDate'
            control={control}
            render={({ field }) => {
              const { ref, ...rest } = field
              return (
                <Datepicker
                  {...rest}
                  placeholder='mm/dd/yyyy'
                  inputRef={ref}
                  onBlur={() => saveFinalizeData()}
                  inputProps={{
                    sx: { fontSize: '14px' },
                  }}
                  error={!!errors.sendDate?.message}
                  helperText={errors.sendDate?.message}
                />
              )
            }}
          />
        </FormItem>

        <FormItem item lg={4} xl={3}>
          <InputLabel htmlFor='sendTime'>Send Time</InputLabel>
          <Controller
            name='sendTime'
            control={control}
            render={({ field }) => {
              const { ref, ...rest } = field
              return (
                <TimeAutocomplete
                  options={timeOptions}
                  inputValue={timeValueLabel}
                  getOptionLabel={(option: TimeOption) => option.title}
                  getOptionDisabled={(option: TimeOption) => option.disabled}
                  popupIcon={<VerticalChevronIcon color='#131319' />}
                  onChange={(e, option: TimeOption) => setValue('sendTime', option?.value)}
                  onBlur={() => saveFinalizeData()}
                  forcePopupIcon
                  freeSolo
                  ListboxProps={{
                    style: { fontSize: '14px' },
                  }}
                  renderInput={(params) => {
                    return (
                      <TimeInput
                        {...params}
                        {...rest}
                        ref={ref}
                        placeholder='09:00 AM'
                        variant='standard'
                        inputProps={{
                          ...params.inputProps,
                          sx: { fontSize: '14px' },
                        }}
                        error={!!errors.sendTime?.message}
                        helperText={errors.sendTime?.message}
                      />
                    )
                  }}
                />
              )
            }}
          />
        </FormItem>
      </Grid>

      <Grid container>
        <FormItem item lg={10} xl={8}>
          <InputLabel
            htmlFor='previewText'
            tooltip={
              <Tooltip
                title='Short message that appears below the subject line in your inbox. Preview text will not impact the content of your email.'
                titleStyles={{
                  fontWeight: 400,
                  fontSize: '12px',
                }}
                placement='top-end'
              />
            }
          >
            Preview Text
          </InputLabel>
          <Controller
            name='previewText'
            control={control}
            render={({ field }) => {
              const { ref, ...rest } = field
              return (
                <Textarea
                  {...rest}
                  ref={ref}
                  maxCharLength={100}
                  placeholder='Enter preview text for your email here'
                  style={{ minHeight: 80, height: 80 }}
                  error={!!errors.previewText?.message}
                  helperText={errors.previewText?.message}
                  onBlur={() => saveFinalizeData()}
                />
              )
            }}
          />
        </FormItem>
      </Grid>
    </Box>
  )
}
