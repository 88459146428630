declare global {
  export interface Window {
    av8: {
      env: {
        NODE_ENV: string
        REACT_APP_ENV: string
        AUTH_SERVICE_URL: string
        BACKEND_APP_API_URL: string
        CONTENT_GRID_SERVICE_URL: string
        PROPERTY_SEARCH_API_URL: string
        VISUAL_MARKETING_API_URL: string
        DH_PROXY_API_URL: string
        FLAGSMITH_ENV_ID: string
        DEBUG_FLAGSMITH: string
        AUTH_DOMAIN: string
        DATADOG_APPLICATION_ID: string
        DATADOG_CLIENT_TOKEN: string
        DATADOG_SITE: string
        REACT_APP_SERVICE_VERSION: string
        REACT_APP_SERVICE_NAME: string
      }
    }
  }
}

const defineWindowEnv = (() => {
  if (!window.av8?.env) window.av8 = { env: {} as any }

  const prev = window.av8.env

  if (process.env.NODE_ENV === 'production') return

  window.av8.env = {
    NODE_ENV: process.env.NODE_ENV ?? prev.NODE_ENV,
    REACT_APP_ENV: process.env.REACT_APP_ENV ?? prev.REACT_APP_ENV,
    AUTH_SERVICE_URL: process.env.AUTH_SERVICE_URL ?? prev.AUTH_SERVICE_URL,
    FLAGSMITH_ENV_ID: process.env.FLAGSMITH_ENV_ID ?? prev.FLAGSMITH_ENV_ID,
    DEBUG_FLAGSMITH: process.env.DEBUG_FLAGSMITH ?? prev.DEBUG_FLAGSMITH,
    AUTH_DOMAIN: process.env.AUTH_DOMAIN ?? prev.AUTH_DOMAIN,
    DATADOG_APPLICATION_ID: process.env.DATADOG_APPLICATION_ID ?? prev.DATADOG_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN: process.env.DATADOG_CLIENT_TOKEN ?? prev.DATADOG_CLIENT_TOKEN,
    DATADOG_SITE: process.env.DATADOG_SITE ?? prev.DATADOG_SITE,
    REACT_APP_SERVICE_VERSION: process.env.REACT_APP_SERVICE_VERSION ?? prev.REACT_APP_SERVICE_VERSION,
    REACT_APP_SERVICE_NAME: process.env.REACT_APP_SERVICE_NAME ?? prev.REACT_APP_SERVICE_NAME,
    BACKEND_APP_API_URL: process.env.BACKEND_APP_API_URL ?? prev.BACKEND_APP_API_URL,
    CONTENT_GRID_SERVICE_URL: process.env.CONTENT_GRID_SERVICE_URL ?? prev.CONTENT_GRID_SERVICE_URL,
    PROPERTY_SEARCH_API_URL: process.env.PROPERTY_SEARCH_API_URL ?? prev.PROPERTY_SEARCH_API_URL,
    VISUAL_MARKETING_API_URL: process.env.VISUAL_MARKETING_API_URL ?? prev.VISUAL_MARKETING_API_URL,
    DH_PROXY_API_URL: process.env.DH_PROXY_API_URL ?? prev.DH_PROXY_API_URL,
  }
})()

export default defineWindowEnv
