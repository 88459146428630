import { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  InputLabel,
  TextField as MuiTextField,
  Typography,
  Select as MuiSelect,
  MenuItem,
  Autocomplete as MuiAutocomplete,
  IconButton,
  InputAdornment,
  Chip,
  Stack,
  FormControl,
  CircularProgress,
} from '@mui/material'
import styled from '@emotion/styled'
import ColoredBox from '../../checkout/components/colored-box'
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import FilterIcon from '@mui/icons-material/TuneOutlined'
import ClearIcon from '@mui/icons-material/Clear'
import { useFormContext, Controller } from 'react-hook-form'
import { useHistory } from 'react-router'
import * as PropertySearchService from '../services/property-search.service'
import { debounce } from '../../shared/utils'
import { MAX_TARGET_AREAS } from '../schemas/target-list-schema'
import { Suggestion } from '@avenue-8/platform-shared-util-frontend'
import { Target, TimeFrames } from '../../domain/target-lists.dto'

type Props = {
  onOpenFiltersModal: () => void
  onOpenCreateTargetListModal: () => void
  agentsFound: number
  loading: boolean
}

const Container = styled.form`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  background: white;
  box-sizing: border-box;
  padding: 4.56rem 8.9rem;
  display: flex;
  flex-direction: column;
`

const TextField = styled(MuiTextField)``

const FilterButton = styled(Button)`
  border-color: #d5d6ea;

  .MuiButton-endIcon {
    margin-left: 2.62rem;
  }
`

const Select = styled(MuiSelect)`
  border: 1px solid #d5d6ea !important;
  border-radius: 4px;
  padding: 0.81rem;
  padding-right: 1.2rem;
  font-size: 0.875rem !important;

  .MuiSelect-select {
    padding: 0 !important;
    padding-right: 1.2rem !important;
  }
`

const Autocomplete = styled(MuiAutocomplete)<{ filled?: boolean }>`
  .MuiChip-root {
    background: #eeeefd;
    border-radius: 4px;
    font-size: 0.875rem;
    padding: 0.625rem 0.94rem;
  }

  .MuiChip-root.Mui-disabled {
    opacity: 1;
  }

  .MuiChip-label {
    padding: 0 !important;
  }

  .MuiChip-deleteIcon {
    color: #131319 !important;
    margin-left: 1.31rem !important;
    margin-right: 0 !important;
  }

  .MuiOutlinedInput-root input {
    width: 100% !important;
    font-size: 0.875rem !important;
    color: black;
    margin: ${(props) => (props.filled ? '1.44rem 0' : '0')};
    margin-left: 1.625rem !important;
  }

  .MuiOutlinedInput-root,
  .MuiOutlinedInput-root.Mui-focused {
    padding: 0.75rem 1.125rem;
  }

  &&.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root,
  &&.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root.Mui-focused {
    padding: ${(props) => (props.filled ? '0.25rem 0.81rem' : '0.75rem 1.125rem')} !important;
  }

  .MuiOutlinedInput-root fieldset {
    border: 0 !important;
  }
  .MuiOutlinedInput-root input {
    padding: 0 !important;
  }

  .MuiOutlinedInput-root.Mui-disabled {
    opacity: 1;
  }
`

const AutocompleteItem = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  padding: 0.75rem 2.75rem !important;
  padding-right: 1rem !important;

  &&:hover {
    background: #eeeefd;
  }

  &&:first-child::before {
    display: block;
    content: 'Result';
    font-size: 0.625rem;
    text-transform: uppercase;
    color: #73738c;
    margin-bottom: 7px;
  }
` as any // TODO: Type this correctly

const SubmitButton = styled(Button)`
  background-color: #2a29a6;
  outline: 0;
  border: 1px solid #2a29a6;
  color: white;

  &&.Mui-disabled {
    opacity: 0.3;
    color: white;
  }

  &&.MuiButton-root {
    background-color: #2a29a6 !important;
  }

  &&.MuiButton-root:hover {
    background-color: #2a29a6 !important;
    outline: 0;
    border: 1px solid #2a29a6;
    color: white;
  }
`

const OptionTitle = styled(Typography)`
  font-size: 0.875rem;
  color: #131319;
  font-weight: 400;
`

const OptionAddress = styled(Typography)`
  color: #8c8ea1;
  font-size: 0.75rem;
  font-weight: 400;
  display: block;
`

// Mock Time Frames
const timeFrames = [
  { title: 'Last 30 days', value: 'last-30-days' },
  { title: 'Last 60 days', value: 'last-60-days' },
  { title: 'Last 3 months', value: 'last-3-months' },
  { title: 'Last 6 months', value: 'last-6-months' },
  { title: 'Last 12 months', value: 'last-12-months' },
  { title: 'Last 2 years', value: 'last-2-years' },
]

const MAX_AGENTS_FOUND = 10000

// It should order suggestions defined and group them in a flat array
const convertSuggestionsToGroupedTargets = (data: { [key: string]: Suggestion[] }): Target[] => {
  return Object.entries({
    'Zip Code': data.zipCodeSuggestions || [],
    City: data.citySuggestions || [],
    Neighborhood: data.neighborhoodSuggestions || [],
    Address: data.addressSuggestions || [],
    County: data.countySuggestions || [],
  })
    .map(([type, suggestions]) =>
      suggestions.map(({ text, ...restSuggestion }) => ({
        title: text,
        description: [restSuggestion.city, restSuggestion.state].filter((text) => !!text).join(', '),
        type,
        suggestionData: restSuggestion,
      }))
    )
    .flat()
}

export const NewTargetList = ({
  onOpenFiltersModal,
  onOpenCreateTargetListModal,
  agentsFound,
  loading,
}: Props): JSX.Element => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()
  const selectedTargets: Target[] = watch('targetArea')
  const timeFrame: TimeFrames = watch('timeFrame')

  const isValid = selectedTargets.length > 0 && timeFrame && agentsFound > 0
  const isTargetAreasFull = selectedTargets.length === MAX_TARGET_AREAS

  const history = useHistory()
  const goBackToCheckout = () => history.push('/checkout')

  const [targets, setTargets] = useState<Target[]>([])
  const debouncedSearch = debounce((search: string) => {
    PropertySearchService.searchArea({
      search,
      filters: {
        zipCode: true,
        city: true,
        neighborhood: true,
        address: true,
        county: true,
      },
    }).then(({ data: suggestions }) => {
      setTargets(convertSuggestionsToGroupedTargets(suggestions))
    })
  }, 500)

  // Make initial search
  useEffect(() => debouncedSearch(''), [])

  const ColoredBoxContent = useCallback((): JSX.Element => {
    if (loading) {
      return <CircularProgress />
    }

    if (agentsFound === null) {
      return (
        <Typography color='secondary' fontSize='1rem'>
          Fill the information to populate the result.
        </Typography>
      )
    }

    if (agentsFound < 0) {
      return (
        <Typography color='#F04F43' fontSize='1rem'>
          It was not possible to retrieve the number of agents. Please try searching again.
        </Typography>
      )
    }

    if (agentsFound > 0 && agentsFound <= MAX_AGENTS_FOUND) {
      return (
        <Box>
          <Typography variant='h1' fontSize='4rem'>
            {agentsFound}
          </Typography>
          <Typography color='secondary' fontSize='1rem'>
            Agents found by your search
          </Typography>
          {agentsFound === MAX_AGENTS_FOUND && (
            <Typography color='#F04F43' fontSize='0.8rem' mt={1}>
              You’ve reached the maximum number of agents for a Target <br />
              List. We recommend refining your filters to be more targeted.
            </Typography>
          )}
        </Box>
      )
    }

    return (
      <Typography color='secondary' fontSize='1rem' textAlign='left'>
        No agents found that match these criteria. <br />
        Try searching again with different parameters.
      </Typography>
    )
  }, [loading, agentsFound])

  return (
    <Container>
      <Box position='relative'>
        <IconButton sx={{ position: 'absolute', left: '-2rem', top: '4px', color: 'black' }} onClick={goBackToCheckout}>
          <ArrowBackIcon sx={{ width: '0.8rem', height: '0.8rem', transform: 'translateX(3px)' }} />
        </IconButton>
        <Typography variant='h4' component='h1'>
          New Targeted List
        </Typography>
      </Box>
      <Typography mt='4.38rem' variant='h4' component='h2'>
        Search
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: '1.375rem' }}>
        <FormControl sx={{ width: '11rem' }}>
          <InputLabel htmlFor='time-frame-select'>Time Frame</InputLabel>
          <Controller
            control={control}
            name='timeFrame'
            render={({ field: { ref, ...fieldRest } }) => (
              <Select
                {...fieldRest}
                startAdornment={
                  <InputAdornment position='start'>
                    <CalendarTodayOutlinedIcon fontSize='small' sx={{ color: 'black' }} />
                  </InputAdornment>
                }
                id='time-frame-select'
              >
                {timeFrames.map((time) => (
                  <MenuItem key={time.value} value={time.value}>
                    {time.title}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <FormControl sx={{ mx: '1rem', width: '65rem' }}>
          <InputLabel htmlFor='targetArea'>Target Area</InputLabel>
          <Controller
            name='targetArea'
            control={control}
            render={({ field: { ref, ...fieldRest } }) => (
              <Autocomplete
                {...fieldRest}
                onChange={(_, newTargets: any) => setValue('targetArea', newTargets)}
                filled={Array.isArray(selectedTargets) && selectedTargets.length > 0}
                options={targets}
                getOptionLabel={(target: Target) => target.title}
                groupBy={(target: Target) => target.type}
                multiple
                filterSelectedOptions
                disabled={isTargetAreasFull}
                limitTags={1}
                getLimitTagsText={(more) => <Chip label={`${more} more`} />}
                renderInput={(params) => (
                  <Box position='relative'>
                    <LocationOnOutlinedIcon
                      sx={{ position: 'absolute', top: '0.75rem', left: '0.94rem', zIndex: '999' }}
                      fontSize='small'
                    />
                    <TextField
                      {...params}
                      onChange={(e) => debouncedSearch(e.target.value)}
                      disabled={isTargetAreasFull}
                      placeholder={
                        isTargetAreasFull
                          ? `Sorry, you can only search for up to ${MAX_TARGET_AREAS} regions.`
                          : 'Search neighborhood, zipcode...'
                      }
                      sx={{ fontSize: '0.875rem' }}
                      error={!!errors?.targetArea?.min?.message}
                    />
                  </Box>
                )}
                renderTags={(tags: any[], getTagProps) => {
                  return (
                    <Stack direction='row' spacing={1} sx={{ paddingLeft: '1.625rem' }}>
                      {tags.map((tag: Target, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          disabled={false}
                          key={tag.title}
                          label={tag.title}
                          deleteIcon={<ClearIcon />}
                        />
                      ))}
                    </Stack>
                  )
                }}
                renderOption={(props, option: Target) => (
                  <AutocompleteItem component='li' {...props}>
                    <OptionTitle>{option.title}</OptionTitle>
                    <OptionAddress>{option.description}</OptionAddress>
                  </AutocompleteItem>
                )}
              />
            )}
          />
        </FormControl>

        <FilterButton sx={{ mt: '1.02rem' }} endIcon={<FilterIcon />} onClick={onOpenFiltersModal}>
          Filters
        </FilterButton>
      </Box>

      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <ColoredBox
          color='grey'
          width='68%'
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', py: '6rem' }}
        >
          <ColoredBoxContent />
        </ColoredBox>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <SubmitButton endIcon={<ArrowForwardIcon />} onClick={onOpenCreateTargetListModal} disabled={!isValid}>
          Create Targeted List
        </SubmitButton>
      </Box>
    </Container>
  )
}
