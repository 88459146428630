import { FinalizeDialog } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'
import { Button } from '@mui/material'
import { useHistory } from 'react-router'
import { FormProvider, useForm } from 'react-hook-form'
import CampaignDetails from '../components/campaign-details'
import CheckoutSummary from '../components/checkout-summary'
import { yupResolver } from '@hookform/resolvers/yup'
import { campaignDetailsSchema } from '../schemas/campaign-details.schema'
import { useOverlayLoading } from '../../contexts/overlay-loading.context'
import { useEmailIntegrations } from '../../contexts/email-integrations.context'
import { useEmailProjects } from '../../contexts/email-projects.context'
import { datadogRum, generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'
import { useEffect, useMemo, useState } from 'react'
import getTimeOptionsArray, { TimeOption } from '../utils/getTimeOptionsArray'
import { FinalizeFormType } from '../../domain/finalize-form'
import { SendPreviewDialog } from './send-preview-dialog'

const Form = styled.form`
  background-color: red;
  margin: 1rem 0;
  width: 100%;
`

const SendPreviewButton = styled(Button)`
  padding: 0.5rem 1.37rem;
  margin-bottom: 0.6rem;
  font-size: 0.7rem;
`

type FormDataProps = {
  csvFile: File | null
  subjectLine: string
  sendDate: Date
  sendTime: string
  previewText: string
  emails: { emailAddress: string }[]
  soiListsIds: string[]
  mlsListsIds: string[]
  targetListsIds: string[]
}

const generateFormDefaults = (): FinalizeFormType => ({
  subjectLine: '',
  sendDate: '',
  sendTime: '',
  previewText: '',
  emails: [{ emailAddress: '' }],
  soiListsIds: [],
  mlsListsIds: [],
  targetListsIds: [],
  csvFile: null,
})

export default function EmailFinalizePage(): JSX.Element {
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false)
  const history = useHistory()
  const { showLoading, hideLoading } = useOverlayLoading()
  const { soiLists, mlsLists, targetLists, submit, uploadCSVAsList, refreshLists } = useEmailIntegrations()

  const { currentProject: project, setOrderId } = useEmailProjects()

  const resolver = yupResolver(campaignDetailsSchema)
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: generateFormDefaults(),
    resolver,
  })
  const { handleSubmit, formState, setError, setValue, watch } = methods
  const watchSendDateField = watch('sendDate')
  const timeOptions = useMemo<TimeOption[]>(
    () => getTimeOptionsArray(watchSendDateField || new Date().toISOString()),
    [watchSendDateField]
  )

  useEffect(() => {
    if (!project?.html) history.replace('/gallery')
  }, [])

  useEffect(() => {
    refreshLists(['target', 'soi', 'mls'])
  }, [])

  const [hasLoadedForm, setHasLoadedForm] = useState(false)
  useEffect(() => {
    if (hasLoadedForm || !project?.finalize) return

    Object.entries(project.finalize).forEach(([key, value]) => {
      if (key === 'csvFile') {
        const fileValue = value && value !== '' ? new File([], (value as { name: string; listIds: string[] }).name) : ''
        setValue(key, fileValue)
        return
      }
      setValue(key as any, value)
    })
    setHasLoadedForm(true)
  }, [project])

  const handleSave = async (data: FormDataProps) => {
    showLoading('Processing your request...')

    try {
      const customListIds = []

      if (project.finalize?.csvFile) {
        customListIds.push(...project.finalize?.csvFile?.listIds)
      } else if (data.csvFile) {
        const file = data.csvFile
        const lists = await uploadCSVAsList(file)

        // We reject empty lists so the agent knows that the file was not uploaded
        if (lists.length <= 0) {
          const message = 'No lists were created, please check your CSV file'

          // Little hack to allow dynamic error message
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setError('csvFile', { type: 'invalid_csv', message })

          throw new Error(message)
        }

        customListIds.push(...lists)
      }

      const sendTimeAsDate = new Date(data.sendTime)

      // Update time on send date
      const date = new Date(
        data.sendDate.getFullYear(),
        data.sendDate.getMonth(),
        data.sendDate.getDate(),
        sendTimeAsDate.getHours(),
        sendTimeAsDate.getMinutes()
      )

      const result = await submit({
        body: project.html,
        title: project.title ?? `Untitled Project - ${new Date().toLocaleDateString()}`,
        rawProjectJson: JSON.stringify(project.rawJSON),
        subject: data.subjectLine,
        sendAt: date.toISOString(),
        previewText: data.previewText,
        to: [
          ...data.emails.map(({ emailAddress }) => emailAddress),
          ...data.soiListsIds,
          ...data.mlsListsIds,
          ...data.targetListsIds,
          ...customListIds,
        ].filter(Boolean),
      })

      // Generate random order id in case of error in the API response (e.g. no order id but sent emails successfully)
      const orderId = result.orderConfirmationId ?? Math.floor(Math.random() * 1000000).toString()

      setOrderId(orderId)

      hideLoading()

      history.push('/checkout/completed')
    } catch (error) {
      hideLoading()
      datadogRum.addError(error)
      alert('Something went wrong with your request. Please try again.')
      console.error(error)
    }
  }

  const handleClose = () => {
    history.push(`/projects/${project.id}`)
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(handleSave)} noValidate>
        <FinalizeDialog
          open
          title='Finalize'
          titleStyle={{
            textTransform: 'uppercase',
          }}
          totalPrice=''
          leftSideBarFirstSlot={<CheckoutSummary />}
          leftSideBarSecondSlot={
            <>
              <SendPreviewButton fullWidth onClick={() => setIsPreviewDialogOpen(true)}>
                Send Preview
              </SendPreviewButton>
              <Button
                variant='contained'
                fullWidth
                size='large'
                type='submit'
                disabled={!formState.isValid}
                {...generateDataAttrs({
                  metaAction: `submit-email-request`,
                })}
              >
                Submit
              </Button>
              <SendPreviewDialog open={isPreviewDialogOpen} onClose={() => setIsPreviewDialogOpen(false)} />
            </>
          }
          mobileSubmitSlot={<></>}
          handleClose={handleClose}
          enumeratedComponentList={[
            {
              title: 'Campaign Details',
              subtitle: '',
              component: <CampaignDetails data={{ soiLists, mlsLists, timeOptions, targetLists }} />,
            },
          ]}
          modalProps={{
            disablePortal: true,
          }}
        />
      </Form>
    </FormProvider>
  )
}
