import {
  FiltersFormValues,
  FiltersParamsRequest,
  FiltersRange,
  PropertyStatus,
  PropertyTypes,
} from '../../domain/target-lists.dto'

export const convertFiltersToParamsRequest = (filters: FiltersFormValues = {}): FiltersParamsRequest => {
  const convertedFilters: FiltersParamsRequest = {}

  const propertyStatusEntries = Object.entries(filters.propertyStatus ?? {}).filter(([key, value]) => value)
  const propertyTypesEntries = Object.entries(filters.propertyTypes ?? {}).filter(([key, value]) => value)

  if (propertyStatusEntries.length) {
    convertedFilters.propertyStatus = propertyStatusEntries.map(([key, _]: [PropertyStatus, boolean]) => key)
  }

  if (propertyTypesEntries.length) {
    convertedFilters.propertyTypes = propertyTypesEntries.map(([key, _]: [PropertyTypes, boolean]) => key)
  }

  if (filters.hasDoormanAttendance) {
    convertedFilters.hasDoormanAttendance = filters.hasDoormanAttendance === 'yes'
  }

  const nonMinMaxFields = ['propertyStatus', 'propertyTypes', 'hasDoormanAttendance']

  const minMaxFilters = Object.fromEntries(
    Object.entries(filters)
      .filter(([key]) => !nonMinMaxFields.includes(key))
      .filter(([_, value]: [key: string, value: FiltersRange]) => value.startAt !== null || value.endAt !== null)
      .map(([key, value]: [key: string, value: FiltersRange]) => {
        const filterRange: FiltersRange = {}

        if (!isNaN(+value.startAt) && +value.startAt !== 0) {
          filterRange.startAt = +value.startAt
        }

        if (!isNaN(+value.endAt) && +value.endAt !== 0) {
          filterRange.endAt = +value.endAt
        }

        return [key, filterRange]
      })
  )

  return {
    ...convertedFilters,
    ...minMaxFilters,
  }
}
