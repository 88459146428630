import { datadogRum, generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'
import { theme } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, SubmitErrorHandler, useFormContext } from 'react-hook-form'
import { CreateTargetListFields } from '../../target-lists.module'

const MAX_LENGTH = 100

interface CreateTargetListModalProps {
  open: boolean
  loading?: boolean
  onSaveClick: (data: CreateTargetListFields) => void
  onCancelClick: () => void
}

const ErrorMessage = styled(FormHelperText)`
  margin-top: 0.25rem;
  color: ${() => theme.palette.error.main};
`

export default function CreateTargetListModal({
  open,
  loading = false,
  onSaveClick,
  onCancelClick,
}: CreateTargetListModalProps): JSX.Element {
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    clearErrors,
  } = useFormContext()
  const [nameLength, setNameLength] = useState(0)
  const [isNameInputFocused, setIsNameInputFocused] = useState(false)

  const errorMessage = errors.name?.message

  useEffect(() => {
    if (!open) {
      setNameLength(0)
      clearErrors('name')
    }
  }, [open])

  const handleSubmitError: SubmitErrorHandler<CreateTargetListFields> = (err) => {
    datadogRum.addError(err, { extra: { action: 'submit create target list' } })
  }

  return (
    <Dialog open={open} maxWidth='lg' PaperProps={{ sx: { width: '30%' } }}>
      {loading ? (
        <Box sx={{ px: 3, py: 12 }} display='flex' justifyContent='center' alignItems='center' data-testid='loading'>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant='h5' sx={{ p: 3, pb: 0 }}>
            Your target list is created!
          </Typography>
          <DialogContent sx={{ pb: 3 }}>
            <Typography sx={{ mb: 3 }}>Do you want to save this search to use again in the future?</Typography>
            <form
              id='create-target-list-form'
              onSubmit={handleSubmit(onSaveClick, handleSubmitError)}
              data-testid='create-target-list-form'
            >
              <Box position='relative'>
                <Controller
                  control={control}
                  name='name'
                  render={({ field: { ref, onChange, onBlur, ...fieldRest } }) => (
                    <>
                      <TextField
                        {...fieldRest}
                        variant='standard'
                        onChange={(e) => {
                          setNameLength(e.target.value.length)
                          onChange(e)
                        }}
                        onFocus={() => setIsNameInputFocused(true)}
                        onBlur={() => {
                          setIsNameInputFocused(false)
                          onBlur()
                        }}
                        inputProps={{ sx: { width: '84%' } }}
                        helperText={
                          <Typography
                            position='absolute'
                            top='12px'
                            right='15px'
                            fontSize='14px'
                            color='#8c8ea1'
                            letterSpacing='initial'
                            height='22px'
                            sx={{
                              backgroundColor: isNameInputFocused ? '#FAFAFF' : 'white',
                              pl: 1,
                            }}
                          >
                            ({nameLength}/{MAX_LENGTH})
                          </Typography>
                        }
                      />
                      {!!errorMessage && <ErrorMessage sx={{ mb: 1 }}>{errorMessage}</ErrorMessage>}
                    </>
                  )}
                />
              </Box>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              type='button'
              variant='text'
              onClick={onCancelClick}
              data-testid='cancel-button'
              {...generateDataAttrs({ metaAction: 'cancel-save-target-list' })}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              form='create-target-list-form'
              disabled={!isValid}
              {...generateDataAttrs({ metaAction: 'save-target-list' })}
            >
              Save the list
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
