import { Controller, useFormContext } from 'react-hook-form'
import { Box, FormHelperText, TextField } from '@mui/material'
import { InputLabel, theme } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'
import NumberFormat from 'react-number-format'
import { forwardRef } from 'react'

interface MinMaxFieldProps {
  fieldName: string
  /**
   * 2 labels to put over of the min and max fields
   * @defaultValue ["Minimum", "maximum"]
   **/
  fieldLabels?: string[]
  fieldProps?: any
}

const StyledLabel = styled(InputLabel)`
  padding-top: 18px !important;
  margin-bottom: 0 !important;
`

const ErrorMessage = styled(FormHelperText)`
  margin-top: 0.25rem;
  color: ${() => theme.palette.error.main};
`

const Fields = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  & > div {
    max-width: calc(50% - 2.25rem);
    & > label {
      min-height: 1em;
    }
  }
`

export const MinMaxField = (props: MinMaxFieldProps): JSX.Element => {
  const { fieldLabels = ['Minimum', 'Maximum'], fieldName, fieldProps } = props
  const {
    formState: { errors },
    control,
  } = useFormContext()

  const minSuffixe = 'startAt'
  const maxSuffixe = 'endAt'
  const minFieldName = `${fieldName}.${minSuffixe}`
  const maxFieldName = `${fieldName}.${maxSuffixe}`

  const errorMessage = errors[fieldName]?.[minSuffixe]?.message || errors[fieldName]?.[maxSuffixe]?.message
  const BaseNumericInput = (props, ref) => <NumberFormat {...props} {...fieldProps} getInputRef={ref} />
  const NumericInput = forwardRef(BaseNumericInput)

  return (
    <>
      <Fields sx={{ mb: errorMessage ? 2 : 3 }}>
        <div>
          <InputLabel htmlFor={minFieldName}>{fieldLabels[0]}</InputLabel>
          <Controller
            control={control}
            name={minFieldName}
            render={({ field: { ref, ...fieldRest } }) => (
              <TextField
                {...fieldRest}
                variant='standard'
                InputProps={{ inputComponent: NumericInput }}
                inputProps={{
                  'data-testid': `${minFieldName}-field`,
                }}
              />
            )}
          />
        </div>
        <StyledLabel>to</StyledLabel>
        <div>
          <InputLabel htmlFor={maxFieldName}>{fieldLabels[1]}</InputLabel>
          <Controller
            name={maxFieldName}
            render={({ field: { ref, ...fieldRest } }) => (
              <TextField
                {...fieldRest}
                variant='standard'
                InputProps={{ inputComponent: NumericInput }}
                inputProps={{
                  'data-testid': `${maxFieldName}-field`,
                }}
              />
            )}
          />
        </div>
      </Fields>
      {!!errorMessage && (
        <ErrorMessage sx={{ mb: 1 }} data-testid='min-max-field-error-message'>
          {errorMessage}
        </ErrorMessage>
      )}
    </>
  )
}
