import { Box, Button, Typography } from '@mui/material'
import styled from '@emotion/styled'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { formatDate } from '../../shared/utils/formatters'
import React, { useRef, useState, useEffect, useMemo } from 'react'
import { EmailProjectDto } from '../../domain/email-project.dto'
import { EditButton } from './edit-button'

import { HistoryUserMenu, generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'
import { UserAvatarDropDown } from '@avenue-8/platform-style-util-frontend'

// Styles
const Container = styled(Box)`
  display: flex;
  padding: 48px 24px;
  padding-right: 30px;
  padding-bottom: 0;
`

const ProjectDetails = styled(Box)`
  flex-grow: 1;
`

const ProjectTitleInput = styled.input`
  font-family: 'Canela', serif;
  font-size: 24px;
  color: #131319;
  border: none;
  outline: none;
  border-bottom: 1px solid #73738c;
  min-width: max-content;
  padding: 0;
`

const SendButton = styled(Button)`
  padding-left: 65px;
  padding-right: 65px;
  margin-right: 42px;
  background-color: #2a29a6;
  color: #fff;

  &:disabled {
    color: white;
    opacity: 0.12;
  }

  &:hover {
    color: white;
    background-color: #2a29a6 !important;
  }
`

const LastSaved = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: #73738c;
`

const ProjectTitle = styled(Typography)`
  font-size: 24px;
  font-family: 'Canela', serif;
  color: #131319;
  display: inline;
  border-bottom: 1px solid transparent;
`

const ActionButtons = styled(Box)`
  display: flex;
  align-items: center;
`

const PreviewButton = styled(Button)`
  padding-left: 52px;
  padding-right: 52px;
  margin-right: 27px;
`

type ProjectHeaderProps = {
  info: Partial<EmailProjectDto>
  onSendButtonClick?: () => void
  onPreviewButtonClick?: () => void
  onTitleChange?: (title: string) => void
  isSaving?: boolean
}

// Component
export const ProjectHeader: React.FC<ProjectHeaderProps> = ({
  info,
  onTitleChange,
  onSendButtonClick,
  onPreviewButtonClick,
  isSaving,
}) => {
  const [isEditingProjectTitle, setIsEditingProjectTitle] = useState(false)
  const [projectTitle, setProjectTitle] = useState<string | undefined>(undefined)
  const [hasSavedTitle, setHasSavedTitle] = useState(false)
  const formattedUpdatedAt = useMemo(() => (info?.updatedAt ? formatDate(info?.updatedAt) : '...'), [info?.updatedAt])
  const projectTitleInputRef = useRef(null)

  useEffect(() => {
    setProjectTitle(info?.title || 'Untitled Project')
  }, [info])

  const onProjectTitleChange = (e) => {
    setProjectTitle(e.target.value)
  }

  function handleTitleChange() {
    onTitleChange?.(projectTitle)
    setHasSavedTitle(true)
    setTimeout(() => setHasSavedTitle(false), 4000) // It should show saved for 4 seconds and then get back to edit
  }

  // It should trigger the title change after pressing the enter key
  const onProjectTitleKeyUp = (e) => {
    // Key Code for Enter is 13
    if (e.keyCode !== 13) return

    e.preventDefault()
    handleTitleChange()
    setIsEditingProjectTitle(!isEditingProjectTitle)
  }

  const onEditButtonClick = () => {
    if (isEditingProjectTitle) {
      handleTitleChange()
    }
    setIsEditingProjectTitle(!isEditingProjectTitle)
    setTimeout(() => {
      projectTitleInputRef?.current?.focus()
    }, 200)
  }

  return (
    <Container data-testid='project-header-container'>
      <ProjectDetails>
        <LastSaved variant='body1'>{isSaving ? 'Saving...' : `Last saved ${formattedUpdatedAt}`}</LastSaved>
        {isEditingProjectTitle ? (
          <ProjectTitleInput
            value={projectTitle}
            onChange={onProjectTitleChange}
            onKeyUp={onProjectTitleKeyUp}
            ref={projectTitleInputRef}
            data-testid='title-input'
          />
        ) : (
          <ProjectTitle variant='h2'>{projectTitle}</ProjectTitle>
        )}

        <EditButton
          isEditing={isEditingProjectTitle}
          hasSaved={hasSavedTitle}
          onClick={onEditButtonClick}
          data-testid='edit-button'
        />
      </ProjectDetails>
      <ActionButtons>
        <PreviewButton
          disabled={isSaving}
          endIcon={<KeyboardArrowRight />}
          onClick={onPreviewButtonClick}
          data-testid='preview-button'
        >
          Preview
        </PreviewButton>
        <SendButton
          disabled={isSaving}
          endIcon={<KeyboardArrowRight />}
          onClick={onSendButtonClick}
          {...generateDataAttrs({
            metaAction: `send-email-project`,
          })}
          data-testid='send-button'
        >
          Send
        </SendButton>
        <HistoryUserMenu UserMenuComponent={UserAvatarDropDown} />
      </ActionButtons>
    </Container>
  )
}
