import './env-setup' // Must be first import
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { theme } from '@avenue-8/platform-style-util-frontend'
import {
  AuthProvider,
  ListingsContextProvider,
  UserDataProvider,
  TeammatesContextProvider,
  HeapProvider,
  FeatureFlagsProvider,
  PropertySearchContextProvider,
  initDatadogRum,
  initDatadogLogs,
} from '@avenue-8/platform-shared-util-frontend'

import { TemplateModule } from './modules/template/template.module'
import { GalleryModule } from './modules/gallery/gallery.module'
import { EmailTemplatesContextProvider } from './modules/contexts/email-templates.context'
import { EmailProjectsContextProvider } from './modules/contexts/email-projects.context'
import { OverlayLoadingContextProvider } from './modules/contexts/overlay-loading.context'
import CheckoutModule from './modules/checkout/checkout.module'
import { ProjectsModule } from './modules/projects/projects.module'
import TargetListsModule from './modules/target-lists/target-lists.module'
import { EmailIntegrationsContextProvider } from './modules/contexts/email-integrations.context'
import { ErrorBoundaryProvider } from './modules/shared/components/error-boundary-provider'
import packageJson from '../package.json'
/* remove-line-from-build */ import { DevModule } from './modules/dev/DevModule'
import 'swiper/swiper.min.css'

export type AppProps = {
  name: string
  singleSpa: any
}

initDatadogRum({ service: packageJson.name, version: '' })
initDatadogLogs({ service: packageJson.name, version: '' })

// a utility to avoid creating a very deep tree of providers
// https://kentcdodds.com/blog/how-to-use-react-context-effectively
const combineProviders =
  (...providers: any[]) =>
  ({ children }: any) =>
    providers.reduceRight((child: any, Parent: any) => <Parent>{child}</Parent>, children)

export default function Root(props: AppProps): JSX.Element {
  const Providers = combineProviders(
    AuthProvider,
    ErrorBoundaryProvider,
    HeapProvider,
    UserDataProvider,
    FeatureFlagsProvider,
    ListingsContextProvider,
    TeammatesContextProvider,
    EmailTemplatesContextProvider,
    EmailProjectsContextProvider,
    EmailIntegrationsContextProvider,
    OverlayLoadingContextProvider,
    PropertySearchContextProvider
  )

  const isDev = process.env.NODE_ENV === 'development'

  return (
    <ThemeProvider theme={theme}>
      <Providers>
        {/* remove-line-from-build */}
        {isDev && (
          <BrowserRouter>
            <Route path='/dev' component={DevModule} />
          </BrowserRouter>
        )}
        <BrowserRouter basename='/account/marketing/emails'>
          <Switch>
            <Route path='/projects/:projectId' component={TemplateModule} />
            <Route path='/gallery' component={GalleryModule} />
            <Route path='/checkout' render={({ match }) => <CheckoutModule url={match.url} />} />
            <Route path='/target-list' component={TargetListsModule} />
          </Switch>
        </BrowserRouter>
        <BrowserRouter basename='/account/marketing'>
          <Route path='/projects' component={ProjectsModule} />
        </BrowserRouter>
      </Providers>
    </ThemeProvider>
  )
}
