import { isValid, isPast, isFuture, isToday, format } from 'date-fns'

export interface TimeOption {
  title: string
  value: Date
  disabled: boolean
}

const getNextHoursDate = (date: Date, additionalHours: number) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() + additionalHours)

export default function getTimeOptionsArray(date: string): TimeOption[] {
  const currentDate = new Date()
  const selectedDate = new Date(date.replace('-', '/'))

  if (isValid(selectedDate)) {
    if (isToday(selectedDate)) {
      return Array.from(new Array(24)).map((number, index) => {
        const nextHoursDate = getNextHoursDate(currentDate, index + 1)
        const formattedDate = format(nextHoursDate, 'hh:mm a')
        return {
          title: formattedDate,
          value: nextHoursDate,
          disabled: nextHoursDate.getHours() <= currentDate.getHours(),
        }
      })
    } else {
      const isDatePast = isPast(selectedDate)

      return Array.from(new Array(24)).map((number, index) => {
        const nextHoursDate = getNextHoursDate(currentDate, index + 1)
        const formattedDate = format(nextHoursDate, 'hh:mm a')
        return {
          title: formattedDate,
          value: nextHoursDate,
          disabled: !!isDatePast,
        }
      })
    }
  } else {
    return Array.from(new Array(24)).map((number, index) => {
      const nextHoursDate = getNextHoursDate(currentDate, index + 1)
      const formattedDate = format(nextHoursDate, 'hh:mm a')
      return {
        title: formattedDate,
        value: nextHoursDate,
        disabled: false,
      }
    })
  }
}
