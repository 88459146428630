import * as React from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import { TargetListBoxSample } from './components/targeted-list/box-sample'
import { Button } from '@mui/material'
import { TargetedListDetailsModalSample } from './components/targeted-list/details-modal-sample'
import { TargetedListRefreshSample } from './components/targeted-list/refresh-sample'
import styled from '@emotion/styled'
import { theme } from '@avenue-8/platform-style-util-frontend'

type SampleGroup = {
  name: string
  path: string
  components: Array<{
    name: string
    path: string
    component: React.FC
  }>
}

const componentGroups: Array<SampleGroup> = [
  {
    name: 'Targeted List',
    path: '/dev/targeted-list',
    components: [
      {
        name: 'List Box',
        path: '/targeted-list-box',
        component: TargetListBoxSample,
      },
      {
        name: 'Details Modal',
        path: '/targeted-list-details-modal',
        component: TargetedListDetailsModalSample,
      },
      {
        name: 'Refresh Modal',
        path: '/targeted-list-refresh-modal',
        component: TargetedListRefreshSample,
      },
    ],
  },
]

export const NavLink = styled(Link)`
  margin-right: 10px;
  display: inline-block;
  color: ${theme.palette.primary.main};
`

export function DevModule(): JSX.Element {
  return (
    <>
      <h1>Dev Module</h1>
      <div>
        {componentGroups.map((group, index) => (
          <NavLink key={index} to={group.path}>
            {group.name}
          </NavLink>
        ))}
      </div>
      <hr />
      <Switch>
        {componentGroups.map((group, index) => (
          <Route
            key={index}
            path={group.path}
            component={() => (
              <>
                <div>
                  Components:{' '}
                  {group.components.map((component, index) => (
                    <NavLink key={index} to={`${group.path}${component.path}`}>
                      {component.name}
                    </NavLink>
                  ))}
                </div>
                <hr />
                <Switch>
                  {group.components.map((component, index) => {
                    return <Route key={index} path={`${group.path}${component.path}`} component={component.component} />
                  })}
                </Switch>
              </>
            )}
          />
        ))}
      </Switch>
    </>
  )
}
