import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import styled from '@emotion/styled'
import { DesktopMac, Smartphone } from '@mui/icons-material'
import { useState } from 'react'
import { MobileSimulationIframe } from './mobile-simulation-iframe'

type PreviewModalProps = {
  title: string
  headline: React.ReactNode
  assetType?: string
  open: boolean
  onClose: () => void
  html?: string
  closeButtonTitle?: string
  onConfirm?: () => void
  confirmButtonTitle?: string
}

const Title = styled(DialogTitle)`
  font-size: 1.5rem;
  font-weight: 400;
`

const AssetArea = styled(Box)`
  background: #fafaff;
  color: #73738c;
  min-height: 60vh;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  margin-top: 0.625rem;
`

const AssetName = styled(Typography)`
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.625rem;
`

const AssetPreview = styled.div`
  margin-top: 0.5rem;
`

const TabLabel = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: capitalize;
`

export const PreviewModal = ({
  title,
  headline,
  assetType,
  open,
  onClose,
  html,
  closeButtonTitle,
  onConfirm,
  confirmButtonTitle,
}: PreviewModalProps): JSX.Element => {
  const [previewMode, setPreviewMode] = useState<'desktop' | 'mobile'>('desktop')
  const handleChangePreviewMode = (_: React.SyntheticEvent, newValue: 'desktop' | 'mobile') => {
    setPreviewMode(newValue)
  }
  return (
    <Dialog maxWidth='lg' onClose={onClose} open={open}>
      <Title>{title}</Title>
      <DialogContent>
        {headline && <DialogContentText>{headline}</DialogContentText>}
        <Box mb={2} mt={2}>
          <Tabs value={previewMode} onChange={handleChangePreviewMode}>
            <Tab
              label={
                <TabLabel variant='body2'>
                  <DesktopMac /> Desktop
                </TabLabel>
              }
              value={'desktop'}
              id={'av8-tab-0'}
            />
            <Tab
              label={
                <TabLabel variant='body2'>
                  <Smartphone /> Mobile
                </TabLabel>
              }
              value={'mobile'}
              id={'av8-tab-1'}
            />
          </Tabs>
        </Box>
        {html && previewMode === 'desktop' && (
          <AssetArea>
            <AssetName>{assetType}</AssetName>
            <AssetPreview dangerouslySetInnerHTML={{ __html: html }} />
          </AssetArea>
        )}
        {html && previewMode === 'mobile' && <MobileSimulationIframe htmlContent={html} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ marginTop: '10px' }} variant='text'>
          {closeButtonTitle || 'Go Back'}
        </Button>
        {!!onConfirm && (
          <Button onClick={onConfirm} sx={{ paddingX: '2rem', marginTop: '10px' }} endIcon={<KeyboardArrowRight />}>
            {confirmButtonTitle || 'Confirm'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
