import { Box, Typography, Button, TextField, Skeleton, Grid } from '@mui/material'
import { useState, useMemo } from 'react'
import styled from '@emotion/styled'
import { useHistory } from 'react-router'
import { useEmailProjects } from '../../contexts/email-projects.context'
import { ProjectCard } from './project-card'
import {
  theme,
  PaginationButton,
  useScreenSize,
  SwiperComponent,
  SwiperSlideComponent,
  SwiperCore,
} from '@avenue-8/platform-style-util-frontend'
import { generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'
import kebabCase from 'lodash.kebabcase'
import { EmailProjectDto } from '../../domain/email-project.dto'

const Container = styled(Box)`
  background: white;
`

const EmailDraftsTitle = styled(Typography)`
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
`

export const ProjectsPage = (): JSX.Element => {
  const { projects = [], deleteProject, isProjectsRequestLoading, cloneProject } = useEmailProjects()
  const sortedProjects = useMemo(
    () => projects.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime()),
    [projects]
  )
  const history = useHistory()
  const { isSmallScreen, isMobile } = useScreenSize()

  const [navigationInformation, setNavigationInformation] = useState<null | {
    currentSlide: number
    slidesPerView: number
    totalSnaps: number
    isPreviousDisabled: boolean
    isNextDisabled: boolean
  }>(null)

  const swiperBreakpoints = useMemo((): { [width: number]: any } => {
    const breakpoints = theme.breakpoints.values
    return {
      [breakpoints.xs]: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      [breakpoints.sm]: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      [breakpoints.md]: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      [breakpoints.lg]: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      [breakpoints.xl]: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        speed: 600,
      },
    }
  }, [])

  const setUpNavigationInformation = (swiper: SwiperCore & { snapIndex: number }) => {
    const slidesPerView = swiperBreakpoints[swiper.currentBreakpoint].slidesPerView as number
    setNavigationInformation({
      isPreviousDisabled: swiper.isBeginning,
      isNextDisabled: swiper.isEnd,
      currentSlide: swiper.snapIndex + 1,
      slidesPerView,
      totalSnaps: Math.ceil(projects.length / slidesPerView),
    })
  }

  const swiperOptions = {
    onInit: setUpNavigationInformation,
    onBreakpoint: setUpNavigationInformation,
    breakpoints: swiperBreakpoints,
    onSnapIndexChange: (swiper: SwiperCore & { snapIndex: number }) => {
      setNavigationInformation((prevNavigationInformation) => ({
        ...prevNavigationInformation,
        isPreviousDisabled: swiper.isBeginning,
        isNextDisabled: swiper.isEnd,
        currentSlide: swiper.snapIndex + 1,
      }))
    },
    navigation: {
      prevEl: `.previous-email-project`,
      nextEl: `.next-email-project`,
    },
  }

  const openProject = (projectId: number) => {
    history.push(`/emails/projects/${projectId}`)
  }

  const cloneProjectHandler = async (project: EmailProjectDto) => {
    const clonedProject = await cloneProject?.(project)

    if (!clonedProject) {
      alert(`We couldn't clone this project. Try again.`)
      return
    }

    history.push(`/emails/projects/${clonedProject.id}`)
  }

  if (isMobile) return null

  return (
    <Container>
      <Box sx={{ mt: '1.5rem', padding: '0 3rem' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <EmailDraftsTitle variant='h2'>Email Drafts</EmailDraftsTitle>
          <PaginationButton
            backDisabled={navigationInformation?.isPreviousDisabled}
            backButtonProps={{
              className: `previous-email-project`,
            }}
            nextDisabled={navigationInformation?.isNextDisabled}
            nextButtonProps={{
              className: `next-email-project`,
            }}
            middleChildren={
              <Typography variant='body1' fontWeight='normal'>
                {navigationInformation?.currentSlide}/{navigationInformation?.totalSnaps}
              </Typography>
            }
          />
        </Box>
        {projects.length > 0 ? (
          <Grid
            container
            sx={{
              '& .swiper-container': {
                justifyContent: 'space-between',
                width: '100%',
              },
              mt: { xs: 1 },
              pt: '1rem',
              pl: '1rem',
              maxWidth: !isSmallScreen ? 'calc(100vw - 184px) !important;' : 'initial',
            }}
          >
            <SwiperComponent swiperOptions={swiperOptions}>
              <div>
                {sortedProjects.map((project) => (
                  <SwiperSlideComponent key={project.id}>
                    <Grid
                      sx={{ mb: 3, mr: 3 }}
                      {...generateDataAttrs({
                        metaName: kebabCase(project.templateId),
                        metaAction: `create-email-from-template-${kebabCase(project.templateId)}`,
                      })}
                    >
                      <ProjectCard
                        id={project.id}
                        title={project.title}
                        headline={project.headline}
                        addressLine={project.addressLine}
                        updatedAt={project.updatedAt}
                        cover={project.cover}
                        onClick={openProject}
                        onDelete={() => deleteProject(project)}
                        onClone={() => cloneProjectHandler(project)}
                      />
                    </Grid>
                  </SwiperSlideComponent>
                ))}
              </div>
            </SwiperComponent>
          </Grid>
        ) : isProjectsRequestLoading ? (
          <Skeleton variant='rectangular' width='100%' height='40vh' sx={{ pt: '1rem', pl: '1rem' }} />
        ) : (
          <Typography variant='h6' fontWeight='lighter'>
            No email drafts
          </Typography>
        )}
      </Box>
    </Container>
  )
}
