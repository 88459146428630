import React, { createContext, useState, useEffect, useContext, useCallback, useMemo } from 'react'
import { useAuthContext } from '@avenue-8/platform-shared-util-frontend'
import { EmailIntegrationsService } from '../shared/services/email-integrations/email-integrations.service.type'
import { EmailIntegrationsServiceFactory } from '../shared/services/email-integrations/email-integrations.service.factory'
import { SOIList } from '../domain/soi-list.dto'
import { MLSList } from '../domain/mls-list.dto'
import { EmailSubmitParamsDTO, EmailSubmitResponseDTO } from '../domain/email-submit.dto'
import { DataState } from '../shared/utils/types'
import { SendPreviewParamsDto } from '../domain/send-preview.dto'
import { PostTargetListDTO, TargetListDto } from '../domain/target-lists.dto'

export type EmailIntegrationsContextType = {
  soiLists: DataState<SOIList[]>
  mlsLists: DataState<MLSList[]>
  targetLists: DataState<TargetListDto[]>
  submit: (data: EmailSubmitParamsDTO) => Promise<EmailSubmitResponseDTO>
  sendPreview: (params: SendPreviewParamsDto) => Promise<boolean>
  postTargetList: (params: PostTargetListDTO) => Promise<any>
  deleteTargetList(id: string): Promise<string>
  uploadCSVAsList: (file: File) => Promise<string[]>
  refreshSOILists: () => void
  refreshLists: (lists: ('mls' | 'soi' | 'target')[]) => void
  service: EmailIntegrationsService
}

export const useEmailIntegrations: () => EmailIntegrationsContextType = () => {
  const context = useContext(EmailIntegrationsContext)
  if (context == null) {
    throw new Error('useEmailIntegrations must be used within EmailIntegrationsContextProvider')
  }
  return context
}

export const EmailIntegrationsContext = createContext<EmailIntegrationsContextType>(null as any)

export const EmailIntegrationsContextFakeProvider: React.FC = (props) => {
  return (
    <EmailIntegrationsContext.Provider
      value={{
        refreshSOILists: () => undefined,
        refreshLists: () => undefined,
        submit: async () => null,
        uploadCSVAsList: async () => [],
        postTargetList: async () => ({}),
        deleteTargetList: async () => '',
        sendPreview: async () => true,
        soiLists: {
          data: [],
          error: null,
          loading: false,
        },
        mlsLists: {
          data: [],
          error: null,
          loading: false,
        },
        targetLists: {
          data: [],
          error: null,
          loading: false,
        },
        service: null as any,
      }}
    >
      {props.children}
    </EmailIntegrationsContext.Provider>
  )
}

export const EmailIntegrationsContextProvider: React.FC = (props) => {
  const { agentId, token } = useAuthContext()

  const [service, setService] = useState<EmailIntegrationsService>()

  // SOI Lists
  const [soiLists, setSOILists] = useState<SOIList[]>([])
  const [loadingSOILists, setLoadingSOILists] = useState<boolean>(false)

  // MLS Lists
  const [mlsLists, setMLSLists] = useState<MLSList[]>([])
  const [loadingMLSLists, setLoadingMLSLists] = useState<boolean>(false)

  // Targeted Lists
  const [targetLists, setTargetLists] = useState<TargetListDto[]>([])
  const [loadingTargetLists, setLoadingTargetLists] = useState<boolean>(false)

  useEffect(() => {
    if (agentId && token) {
      const emailTemplatesService = EmailIntegrationsServiceFactory(token, agentId)
      setService(emailTemplatesService)
    }
  }, [agentId, token])

  useEffect(() => {
    if (service) {
      refreshSOILists()
      refreshMLSLists()
      refreshTargetedLists()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service])

  const submit = async (data: EmailSubmitParamsDTO) => {
    if (service) {
      try {
        const result = await service.submit(data)

        if (!result.success) {
          throw new Error('Email integration failed')
        }

        return result.data
      } catch (error) {
        console.error(error)
        throw error
      }
    }
  }

  const uploadCSVAsList = async (file: File) => {
    if (service) {
      try {
        const result = await service.uploadCSVFileAsList(file)

        if (result.success) {
          return result.data
        }

        return []
      } catch (error) {
        console.error(error)
        return []
      }
    }
  }

  const refreshSOILists = () => {
    if (service) {
      setLoadingSOILists(true)

      service
        .getSOIList()
        .then((response) => {
          if (response.success) {
            // Filter out the SOI Lists that doesn't contain any contacts
            const data = response.data.filter((c) => c.count > 0)

            setSOILists(data)
          }
        })
        .catch((error) => {
          console.error('Failed to get SOI lists', error.message, error)
        })
        .finally(() => {
          setLoadingSOILists(false)
        })
    }
  }

  const refreshMLSLists = () => {
    if (service) {
      setLoadingMLSLists(true)

      service
        .getMLSList()
        .then((response) => {
          if (response.success) {
            // Filter out the SOI Lists that doesn't contain any contacts
            const data = response.data.filter((c) => c.count > 0)

            setMLSLists(data)
          }
        })
        .catch((error) => {
          console.error('Failed to get MLS lists', error.message, error)
        })
        .finally(() => {
          setLoadingMLSLists(false)
        })
    }
  }

  const refreshTargetedLists = () => {
    if (service) {
      setLoadingTargetLists(true)

      service
        .getTargetLists()
        .then((response) => {
          if (!response.success) return

          setTargetLists(response.data)
        })
        .catch((error) => {
          console.error('Failed to get Target lists', error.message, error)
        })
        .finally(() => {
          setLoadingTargetLists(false)
        })
    }
  }

  const postTargetList = useCallback(
    async (params: PostTargetListDTO) => {
      if (service) {
        return service.postTargetList(params)
      }
    },
    [service]
  )

  const deleteTargetList = async (id: string) => {
    if (service) {
      const response = await service.deleteTargetList(id)
      if (response.success) {
        return response.data
      }
      throw new Error(`Error deleting target list ${id}`)
    }
  }

  const sendPreview = async (params: SendPreviewParamsDto) => {
    if (!service) return

    try {
      const result = await service.sendPreview(params)

      if (!result.success) {
        throw new Error('Unable to send preview')
      }

      return true
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const refreshLists = (lists: ('mls' | 'soi' | 'target')[]) => {
    lists.forEach((listType) => {
      if (listType === 'mls') refreshMLSLists()
      if (listType === 'soi') refreshSOILists()
      if (listType === 'target') refreshTargetedLists()
    })
  }

  return (
    <EmailIntegrationsContext.Provider
      value={{
        refreshSOILists,
        refreshLists,
        submit,
        uploadCSVAsList,
        sendPreview,
        postTargetList: postTargetList,
        deleteTargetList,
        soiLists: {
          data: soiLists,
          loading: loadingSOILists,
          error: null,
        },
        mlsLists: {
          data: mlsLists,
          loading: loadingMLSLists,
          error: null,
        },
        targetLists: {
          data: targetLists,
          loading: loadingTargetLists,
          error: null,
        },
        service,
      }}
    >
      {props.children}
    </EmailIntegrationsContext.Provider>
  )
}
