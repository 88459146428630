import { createContext, useState, useEffect, useContext, PropsWithChildren, FC } from 'react'
import { useAuthContext } from '@avenue-8/platform-shared-util-frontend'
import { EmailTemplatesServiceFactory } from '../shared/services/email-templates/email-templates.service.factory'
import { EmailTemplatesService } from '../shared/services/email-templates/email-templates.service.type'
import { EmailTemplateResponseDTO } from '../domain/email-templates.dto'

export type ListingsContextValue = {
  emailTemplates: EmailTemplateResponseDTO[]
}

export const useEmailTemplates: () => ListingsContextValue = () => useContext(EmailTemplatesContext)

export const EmailTemplatesContext = createContext<ListingsContextValue>({
  emailTemplates: [],
})

export const EmailTemplatesContextProvider: FC = (props) => {
  const { agentId, token } = useAuthContext()
  const [emailTemplatesService, setEmailTemplatesService] = useState<EmailTemplatesService>(null)
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplateResponseDTO[]>(null)

  useEffect(() => {
    if (agentId && token) {
      const emailTemplatesService = EmailTemplatesServiceFactory(token, agentId)
      setEmailTemplatesService(emailTemplatesService)
    }
  }, [agentId, token])

  useEffect(() => {
    if (emailTemplatesService) {
      emailTemplatesService
        .getTemplates()
        .then((response) => {
          if (response.success) {
            const { data } = response

            // Sort data by order
            data.sort((a, b) => a.order - b.order)

            setEmailTemplates(data)
          }
        })
        .catch((error) => {
          console.error('Failed to get email templates', error.message, error)
        })
    }
  }, [emailTemplatesService])

  return <EmailTemplatesContext.Provider value={{ emailTemplates }}>{props.children}</EmailTemplatesContext.Provider>
}
