import { useEffect, useState, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { usePropertySearch, GetAgentsByFiltersParams } from '@avenue-8/platform-shared-util-frontend'
import { FiltersFormValues, Target, TimeFrames } from '../domain/target-lists.dto'
import FiltersModal from './components/filters-modal/filters-modal'
import { targetListSchema } from './schemas/target-list-schema'
import { convertFiltersToParamsRequest } from './utils/convertFiltersData'
import { NewTargetList } from './components/new-target-list'
import CreateTargetListModal from './components/create-target-list-modal/create-target-list-modal'
import { getDateRangeByTimeFrame } from './utils/getDateRangeByTimeframe'
import { useEmailIntegrations } from '../contexts/email-integrations.context'

export type CreateTargetListFields = {
  name: string
  timeFrame: TimeFrames
  targetArea: Target[]
}

export default function TargetListsModule(): JSX.Element {
  const { propertySearchService } = usePropertySearch()
  const { postTargetList } = useEmailIntegrations()

  const history = useHistory()

  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState<boolean>(false)
  const [isCreateTargetListModalOpen, setIsCreateTargetListModalOpen] = useState<boolean>(false)
  const [agentsFound, setAgentsFound] = useState<number>(null)
  const [filters, setFilters] = useState<FiltersFormValues>(null)
  const [loadingAgents, setLoadingAgents] = useState<boolean>(false)
  const [isCreatingList, setIsCreatingList] = useState<boolean>(false)
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false)

  const resolver = yupResolver(targetListSchema)
  const formMethods = useForm<CreateTargetListFields>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      timeFrame: 'last-3-months',
      targetArea: [],
    },
    resolver,
  })
  const { resetField, getValues, watch, setError } = formMethods

  const targetAreaField = watch('targetArea')
  const timeFrameField = watch('timeFrame')

  useEffect(() => {
    if (!isFormChanged && (filters || targetAreaField.length > 0 || timeFrameField !== 'last-3-months')) {
      setIsFormChanged(true)
    }

    if (isFormChanged) {
      searchAgentsByFilters()
    }
  }, [filters, targetAreaField, timeFrameField, isFormChanged])

  const openFiltersModal = () => setIsFiltersModalOpen(true)

  const closeFiltersModal = () => {
    setIsFiltersModalOpen(false)
  }

  const applyFilters = async (filters: FiltersFormValues) => {
    setFilters(filters)
    closeFiltersModal()
  }

  const openCreateTargetListModal = () => setIsCreateTargetListModalOpen(true)

  const closeCreateTargetListModal = () => {
    resetField('name')
    setIsCreateTargetListModalOpen(false)
  }

  const saveTargetList = ({ name, targetArea, timeFrame }: CreateTargetListFields) => {
    setIsCreatingList(true)

    postTargetList({
      name,
      targetArea,
      timeFrame,
      filters: filters ?? {},
    })
      .then((response) => {
        if (response.success) {
          history.push('checkout')
        } else {
          throw new Error('It was not possible to create a target list.')
        }
      })
      .catch(() => {
        setError('name', { type: 'custom', message: 'Unable to save your target list. Please try again.' })
        setIsCreatingList(false)
      })
  }

  const searchAgentsByFilters = useCallback(() => {
    setLoadingAgents(true)

    const targetArea = getValues('targetArea')

    const body: GetAgentsByFiltersParams = {
      areas: targetArea.map((suggestion) => suggestion.suggestionData),
      listDate: getDateRangeByTimeFrame(getValues('timeFrame')),
      ...(filters ? convertFiltersToParamsRequest(filters) : {}),
    }

    propertySearchService
      ?.getAgentsByFilters(body)
      ?.then((response) => {
        if (response.success) {
          setAgentsFound(response.data?.length)
        } else {
          throw Error('Error fetching agents by filters.')
        }
      })
      ?.catch(() => setAgentsFound(-1))
      ?.finally(() => setLoadingAgents(false))
  }, [filters, propertySearchService])

  return (
    <FormProvider {...formMethods}>
      <NewTargetList
        onOpenFiltersModal={openFiltersModal}
        onOpenCreateTargetListModal={openCreateTargetListModal}
        agentsFound={agentsFound}
        loading={loadingAgents}
      />
      <FiltersModal open={isFiltersModalOpen} onClose={closeFiltersModal} applyFilters={applyFilters} />
      <CreateTargetListModal
        loading={isCreatingList}
        open={isCreateTargetListModalOpen}
        onSaveClick={saveTargetList}
        onCancelClick={closeCreateTargetListModal}
      />
    </FormProvider>
  )
}
