import { useState, useRef } from 'react'
import { Box, Typography, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import styled from '@emotion/styled'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteIcon from '@mui/icons-material/Delete'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import { format } from 'date-fns'
import en from 'date-fns/locale/en-US'
import { ImageUnavailable } from '../../email-editor/components/image-unavailable'
import { generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'

type ProjectCardProps = {
  id: number
  title?: string
  headline?: string
  addressLine?: string
  updatedAt: Date
  cover?: string
  onClick?: (projectId: number) => void
  onDelete?: (projectId: number) => void
  onClone?: () => void
}

function formatProjectUpdateDate(date: Date) {
  return format(date, "LL'/'dd'/'yyyy", { locale: en })
}

const ProjectCardContainer = styled(Box)`
  background-color: #f8f8f9;
  border: 1px solid #d5d6ea;
  border-radius: 4px;
  cursor: default;

  &:hover {
    border-color: #b5b6c9;
  }
`

const ProjectThumbnail = styled(Box)`
  width: 100%;
  height: 10rem;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  overflow-y: hidden;

  && > img {
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
`

const ProjectContainer = styled(Box)`
  padding: 1rem;
`

const ProjectTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  line-height: 125%;
`

const ProjectDetails = styled(Typography)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1.375rem;
`

const ProjectText = styled(Typography)`
  font-size: 0.75rem;
  color: #73738c;
  line-height: 100%;
`

const MoreIconButton = styled(IconButton)`
  color: #a8abcd;
`

const ProjectHeadline = styled(Typography)`
  font-size: 1.125rem !important;
  text-align: center;
  color: #2a29a6;
  line-height: 125%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 1rem;
`

const ProjectAddressLine = styled(Typography)`
  font-size: 0.85rem !important;
  text-align: center;
  line-height: 133%;
  margin-top: 4.5px;
`

export const ProjectCard = ({
  id,
  title,
  headline,
  addressLine,
  cover,
  updatedAt,
  onClick,
  onDelete,
  onClone,
}: ProjectCardProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = !!anchorEl
  const openMenu = (e) => setAnchorEl(e.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  return (
    <ProjectCardContainer>
      <ProjectThumbnail onClick={() => onClick?.(id)}>
        {cover ? (
          <>
            <img src={cover || ''} />
            <ProjectHeadline variant='h1'>{headline || 'Headline'}</ProjectHeadline>
            <ProjectAddressLine variant='h1'>{addressLine || 'Address Line'}</ProjectAddressLine>
          </>
        ) : (
          <ImageUnavailable />
        )}
      </ProjectThumbnail>
      <ProjectContainer>
        <ProjectTitle variant='body1'>{title || 'Untitled Project'}</ProjectTitle>
        <ProjectDetails>
          <Box>
            <ProjectText variant='subtitle2' sx={{ marginBottom: '0.5rem' }}>
              Email Blast
            </ProjectText>
            <ProjectText variant='subtitle2'>updated {formatProjectUpdateDate(updatedAt)}</ProjectText>
          </Box>
          <MoreIconButton
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={openMenu}
          >
            <MoreVertIcon />
          </MoreIconButton>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => onClone?.()} {...generateDataAttrs({ metaAction: 'email-clone-project' })}>
              <ListItemIcon>
                <ContentCopyIcon />
              </ListItemIcon>
              <ListItemText>Clone</ListItemText>
            </MenuItem>

            <MenuItem onClick={() => onDelete?.(id)} {...generateDataAttrs({ metaAction: 'email-delete-project' })}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </Menu>
        </ProjectDetails>
      </ProjectContainer>
    </ProjectCardContainer>
  )
}
