import { Route } from 'react-router-dom'
import EmailFinalizePage from './components/email-finalize-page'
import OrderCompletedModal from './components/order-completed-modal'

export default function CheckoutModule({ url }: { url: string }): JSX.Element {
  return (
    <>
      <Route exact path={`${url}/`} component={EmailFinalizePage} />
      <Route exact path={`${url}/completed`} component={OrderCompletedModal} />
    </>
  )
}
