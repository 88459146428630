import { FiltersRange, TimeFrames } from '../../domain/target-lists.dto'
import { sub, format } from 'date-fns'

export const getDateRangeByTimeFrame = (timeFrame: TimeFrames): FiltersRange<string> => {
  const today = new Date()

  const startAt = sub(today, {
    days: timeFrame === 'last-30-days' ? 30 : timeFrame === 'last-60-days' ? 60 : 0,
    months:
      timeFrame === 'last-3-months' ? 3 : timeFrame === 'last-6-months' ? 6 : timeFrame === 'last-12-months' ? 12 : 0,
    years: timeFrame === 'last-2-years' ? 2 : 0,
  })

  return {
    startAt: format(startAt, 'yyyy-MM-dd'),
    endAt: format(today, 'yyyy-MM-dd'),
  }
}
