import StyreneRegular from '../assets/fonts/styrene_regular.otf'
import { EmailDesignTags } from '../../domain/email-design-tags'
import { WidgetData } from '../../shared/utils/types'
import { UserDataDto } from '@avenue-8/platform-shared-util-frontend'

const customToolsBaseUrl = process.env.REACT_APP_CUSTOM_TOOL_URL
const projectId = parseInt(process.env.REACT_APP_UNLAYER_PROJECT_ID, 10)

const customFonts = [
  {
    label: 'Canela',
    value: 'Canela,serif',
    url: 'https://f.hubspotusercontent20.net/hubfs/8817073/Canela-Regular.woff',
  },
  {
    label: 'Styrene',
    value: 'Styrene,serif',
    url: StyreneRegular,
  },
]

function getDesignTags(agent: UserDataDto): EmailDesignTags {
  return {
    agentFirstName: agent?.firstName || 'Your First Name',
    agentLastName: agent?.lastName || 'Your Last Name',
    agentPhoneNumber: agent?.profile?.phone ?? agent?.phone ?? 'Your Phone Number',
    agentEmail: agent?.email ?? 'Your Email',
    dre: agent?.dre ?? 'Your DRE',
    headshot: agent?.profile?.photo ?? '',
    propertyUrl: 'https://propertyurl.com',
    listingStage: 'Just Listed',
  }
}

type EmailEditorConfigOptions = {
  templateId: number
  data: WidgetData
}

export function getEmailEditorConfigOptions({ templateId, data }: EmailEditorConfigOptions): any {
  return {
    projectId,
    templateId,
    displayMode: 'email',
    customCSS: ['.blockbuilder-preview { background: white !important; }'],
    customJS: [`${customToolsBaseUrl}/unlayer-editor.js`],
    fonts: {
      showDefaultFonts: true,
      customFonts,
    },
    editor: {
      autoSelectOnDrop: true,
    },
    features: {
      audit: true,
    },
    appearance: {
      theme: 'light',
      panels: {
        tools: {
          collapsible: false,
          dock: 'left',
        },
      },
    },
    tools: {
      'custom#listings': {
        data,
        properties: {
          listingsSelection: {
            editor: {
              data,
            },
          },
          listingsImagesSelection: {
            editor: {
              data,
            },
          },
          findListing: {
            editor: {
              data,
            },
          },
        },
        enabled: true,
        position: 1,
      },
      'custom#teammates': {
        data,
        properties: {
          yourTeammate: {
            editor: {
              data,
            },
          },
          containerPadding: {
            value: '0px',
          },
        },
        enabled: true,
      },
      heading: {
        position: 3,
      },
      text: {
        position: 4,
      },
      image: {
        enabled: true,
      },
      button: {
        enabled: true,
        properties: {
          buttonColors: {
            value: {
              color: '#FFFFFF',
              backgroundColor: '#2a29a6',
              hoverColor: '#FFFFFF',
              hoverBackgroundColor: '#2a29a6',
            },
          },
          borderRadius: {
            value: '50px',
          },
          fontSize: {
            value: '14px',
          },
          padding: {
            value: '15px 28px',
          },
        },
      },
      divider: {
        enabled: true,
      },
      html: {
        enabled: false,
      },
      menu: {
        enabled: false,
      },
      video: {
        enabled: true,
      },
      social: {
        enabled: true,
        properties: {
          icons: {
            value: {
              iconType: 'squared',
              icons: [
                { name: 'Facebook', url: 'https://facebook.com/' },
                { name: 'Instagram', url: 'https://instagram.com/' },
                { name: 'LinkedIn', url: 'https://linkedin.com/' },
              ],
            },
          },
          spacing: {
            value: '8',
          },
        },
      },
    },
    designTags: getDesignTags(data.agent),
    locale: 'en-US',
    translations: {
      'en-US': {
        'tabs.audit.missing_tool_name': 'Adjust',
        'tabs.audit.empty.description': 'Audit allows you to view all errors in your design and content.',
      },
    },
  }
}
