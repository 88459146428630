import { AuthedFetch, ResponseType, VISUAL_MARKETING_API_URL } from '@avenue-8/platform-shared-util-frontend'
import { EmailProjectDto } from '../../../domain/email-project.dto'
import { CreateEmailProjectDto, EmailProjectsService, UpdateEmailProjectDto } from './email-projects.service.type'

type EmailProjectResponse = {
  id: number
  agent: { id: string; [key: string]: any }
  templateId: number
  title?: string
  headline?: string
  addressLine?: string
  designData?: any
  cover?: string
  updatedAt: string
  finalizeData?: {
    csvFile?: {
      listIds: string[]
      name: string
    }
    subjectLine: string
    sendDate: string
    sendTime: string
    previewText: string
    customList: boolean
    mlsList: boolean
    soiList: boolean
    targetList: boolean
    emails: { emailAddress: string }[]
    soiListsIds: string[]
    mlsListsIds: string[]
    targetListsIds: string[]
  }
}

const BASE_URL = VISUAL_MARKETING_API_URL

export class EmailProjectsServicesImpl implements EmailProjectsService {
  async getProjects(): Promise<ResponseType<EmailProjectDto[]>> {
    try {
      const res = await AuthedFetch(`${BASE_URL}/email-projects`)
      const data = (await res.json()) as EmailProjectResponse[]

      return {
        success: true,
        data: data.map((project) => ({
          id: project.id,
          templateId: project.templateId,
          title: project.title,
          headline: project.headline,
          addressLine: project.addressLine,
          cover: project.cover,
          updatedAt: new Date(project.updatedAt),
        })),
      }
    } catch (error) {
      console.error(error)
      return {
        success: false,
      }
    }
  }

  async getProjectById(id: number): Promise<ResponseType<EmailProjectDto>> {
    try {
      const res = await AuthedFetch(`${BASE_URL}/email-projects/${id}`)
      const project = (await res.json()) as EmailProjectResponse

      if (!res.ok) throw new Error('Request failed')

      return {
        data: {
          id: project.id,
          templateId: project.templateId,
          title: project.title,
          headline: project.headline,
          addressLine: project.addressLine,
          cover: project.cover,
          updatedAt: new Date(project.updatedAt),
          rawJSON: project.designData,
          finalize: project.finalizeData && {
            csvFile: project.finalizeData.csvFile && {
              name: project.finalizeData.csvFile.name,
              listIds: project.finalizeData.csvFile.listIds,
            },
            subjectLine: project.finalizeData.subjectLine,
            sendDate: project.finalizeData.sendDate,
            sendTime: project.finalizeData.sendTime ? new Date(project.finalizeData.sendTime) : undefined,
            previewText: project.finalizeData.previewText,
            emails: project.finalizeData.emails || [],
            customList: project.finalizeData.customList || false,
            mlsList: project.finalizeData.mlsList || false,
            soiList: project.finalizeData.soiList || false,
            targetList: project.finalizeData.targetList || false,
            soiListsIds: project.finalizeData.soiListsIds || [],
            mlsListsIds: project.finalizeData.mlsListsIds || [],
            targetListsIds: project.finalizeData.targetListsIds || [],
          },
        },
        success: true,
      }
    } catch (error) {
      console.error(error)
      return {
        success: false,
      }
    }
  }

  async updateProject(id: number, payload: UpdateEmailProjectDto): Promise<ResponseType<EmailProjectDto>> {
    try {
      const res = await AuthedFetch(`${BASE_URL}/email-projects/${id}`, {
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const project = (await res.json()) as EmailProjectResponse

      return {
        data: {
          id: project.id,
          templateId: project.templateId,
          title: project.title,
          headline: project.headline,
          addressLine: project.addressLine,
          cover: project.cover,
          updatedAt: new Date(project.updatedAt),
        },
        success: true,
      }
    } catch (error) {
      console.error(error)
      return {
        success: false,
      }
    }
  }

  async deleteProject(id: number): Promise<{ success: boolean }> {
    try {
      await AuthedFetch(`${BASE_URL}/email-projects/${id}`, {
        method: 'DELETE',
      })

      return {
        success: true,
      }
    } catch (error) {
      console.error(error)
      return {
        success: false,
      }
    }
  }

  async createProject(payload: CreateEmailProjectDto): Promise<ResponseType<EmailProjectDto>> {
    try {
      const res = await AuthedFetch(`${BASE_URL}/email-projects`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })

      const project = (await res.json()) as EmailProjectResponse

      return {
        success: true,
        data: {
          id: project.id,
          templateId: project.templateId,
          updatedAt: new Date(project.updatedAt),
        },
      }
    } catch (error) {
      console.error(error)
      return {
        success: false,
      }
    }
  }

  async cloneProject(emailProjectId: number): Promise<ResponseType<EmailProjectDto>> {
    try {
      const res = await AuthedFetch(`${BASE_URL}/email-projects/clone`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailProjectId,
        }),
      })

      const emailProject = (await res.json()) as EmailProjectResponse

      return {
        success: true,
        data: {
          ...emailProject,
          updatedAt: new Date(emailProject.updatedAt),
          rawJSON: emailProject.designData,
        },
      }
    } catch (err) {
      console.error(err)
      return {
        success: false,
      }
    }
  }
}
