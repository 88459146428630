import styled from '@emotion/styled'
import { Typography, Box } from '@mui/material'
import { useEmailProjects } from '../../contexts/email-projects.context'

const SummaryWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const ListingName = styled(Typography)`
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
`

export default function CheckoutSummary(): JSX.Element {
  const { currentProject: project } = useEmailProjects()
  // We can't bring the selected list from the Unlayer context because it's not on our frame
  // const listingName = '152 S Laurel Ave'

  return (
    <SummaryWrapper>
      <div>
        <ListingName color='secondary' mb={1}>
          Email Blast
        </ListingName>
        <div
          style={{
            height: `420px`,
            overflow: `hidden`,
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              zoom: 0.4,
            }}
            dangerouslySetInnerHTML={{ __html: project.html }}
          />
        </div>
      </div>
    </SummaryWrapper>
  )
}
