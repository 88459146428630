import { CloseIcon, Button } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { useUserDataContext } from '@avenue-8/platform-shared-util-frontend'
import { useEmailProjects } from '../../contexts/email-projects.context'
import React from 'react'

const InformationHeading = styled(Typography)`
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
`

const Information = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
`

const CustomDialogContent = styled(DialogContent)`
  overflow: hidden;
  padding: 24px 32px;
`

const CustomDialogActions = styled(DialogActions)`
  padding: 0 32px 32px;
`

const OrderCompletedModal: React.FC = () => {
  const agent = useUserDataContext()
  const { currentProject: project } = useEmailProjects()

  const goToDiscoveryPage = () => {
    const discoveryUrl = `${window.location.origin}/account/marketing/discover`
    window.location.href = discoveryUrl
  }

  const goToOrders = () => goToDiscoveryPage()

  return (
    <Dialog maxWidth='md' onClose={() => undefined} open={true}>
      <DialogTitle id='alert-dialog-title' sx={{ padding: 4, paddingBottom: 3 }}>
        <Grid container justifyContent='space-between' position='relative'>
          <Grid item xs={8} container direction='column'>
            <Typography fontSize={24} fontFamily='Canela' component='h2' fontWeight={300}>
              We’ve received your request!
            </Typography>
            <Typography variant='body2' color='#8C8EA1' fontWeight={400} marginTop='8px'>
              Order # {project?.orderId}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            onClick={goToDiscoveryPage}
            position='absolute'
            right='0'
            top='5px'
            style={{ cursor: 'pointer' }}
          >
            <CloseIcon size={24} />
          </Grid>
        </Grid>
      </DialogTitle>
      <CustomDialogContent>
        <Typography fontWeight={400}>We’ve received your request and are processing it now.</Typography>

        <Grid container mt={3}>
          <Grid item md={6} mb={3}>
            <InformationHeading>Confirmation Email</InformationHeading>
          </Grid>
          <Grid item md={6}>
            <Information>{agent?.email}</Information>
          </Grid>
          <Grid item md={6} mb={4}>
            <InformationHeading>Asset</InformationHeading>
          </Grid>
          <Grid item md={6}>
            <Information>
              <span style={{ display: 'block' }}>Email</span>
            </Information>
          </Grid>
          <Grid item md={6}>
            <InformationHeading>Total</InformationHeading>
          </Grid>
          <Grid item md={6}>
            <Information>n/a</Information>
          </Grid>
        </Grid>
      </CustomDialogContent>
      <CustomDialogActions>
        <Button onClick={goToDiscoveryPage}>Done</Button>
      </CustomDialogActions>
    </Dialog>
  )
}

export default OrderCompletedModal
