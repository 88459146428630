import { theme } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import * as React from 'react'
import { TargetedListRefreshSummaryDto } from '../../domain/refresh-targeted-list.dto'

const ErrorText = styled(Typography)`
  color: ${() => theme.palette.error.main};
`

const UnorderedList = styled.ul`
  font-family: ${() => theme.typography.fontFamily};
  font-size: 0.8rem;
  line-height: 1.4rem;
  margin: 0.5rem 0 1.5rem 0;
  padding-left: 1.5rem;
`

export function TargetedListRefreshModal(props: {
  title: string
  open: boolean
  onClose: () => void
  getRefreshPreview: () => Promise<TargetedListRefreshSummaryDto>
  refresh: () => Promise<void>
}): JSX.Element {
  const [previewState, setPreviewState] = React.useState<{
    loading: boolean
    error: Error
    data: TargetedListRefreshSummaryDto | null
  }>({
    loading: true,
    error: null,
    data: null,
  })

  const [refreshState, setRefreshing] = React.useState<{
    processing: boolean
    done: boolean
    error: Error
  }>({
    processing: false,
    done: false,
    error: null,
  })

  const handleDialogEnter = React.useCallback(async () => {
    // reset state
    setPreviewState({
      loading: true,
      error: null,
      data: null,
    })
    setRefreshing({
      processing: false,
      done: false,
      error: null,
    })
    props
      .getRefreshPreview()
      .then((data) => {
        setPreviewState({
          loading: false,
          error: null,
          data,
        })
      })
      .catch((error) => {
        setPreviewState({
          loading: false,
          error,
          data: null,
        })
      })
  }, [props.getRefreshPreview])

  const handleRefresh = React.useCallback(async () => {
    setRefreshing({
      processing: true,
      done: false,
      error: null,
    })
    props
      .refresh()
      .then(() => {
        setRefreshing({
          processing: false,
          done: true,
          error: null,
        })
      })
      .catch((error) => {
        setRefreshing({
          processing: false,
          done: false,
          error,
        })
      })
  }, [props.refresh])

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      scroll='paper'
      fullWidth
      TransitionProps={{
        unmountOnExit: true,
        onEnter: handleDialogEnter,
      }}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        {(() => {
          if (refreshState.done) {
            return <Typography variant='body2'>The list has been successfully updated.</Typography>
          }
          if (previewState.loading) {
            return <Typography variant='body2'>Loading refresh preview...</Typography>
          }
          if (previewState.error) {
            return <ErrorText variant='body2'>Preview loading failed.</ErrorText>
          }
          if (previewState.data == null) {
            return <Typography variant='body2'>The preview response is empty.</Typography>
          }
          if (previewState.data.contactsToAdd === 0 && previewState.data.contactsToRemove === 0) {
            return (
              <>
                <Typography variant='body2'>No changes to be applied.</Typography>
                {refreshState.error && <ErrorText variant='body2'>Failed to refresh</ErrorText>}
              </>
            )
          }
          const data = previewState.data
          return (
            <>
              <Typography variant='body2'>Updates Found</Typography>
              <UnorderedList>
                <li>Add: {data.contactsToAdd} new emails</li>
                <li>Delete: {data.contactsToRemove} existing emails</li>
              </UnorderedList>

              <Typography variant='body2'>Email Count</Typography>
              <UnorderedList>
                <li>Current: {data.previousTotal}</li>
                <li>Refreshed: {data.newTotal}</li>
              </UnorderedList>

              <Typography variant='body2'>Press the button Refresh to apply the changes.</Typography>

              {refreshState.error && <ErrorText variant='body2'>Failed to refresh</ErrorText>}
            </>
          )
        })()}
      </DialogContent>
      <DialogActions>
        {!refreshState.processing && (
          <Button disabled={refreshState.processing} onClick={props.onClose}>
            {refreshState.done ? 'Close' : 'Cancel'}
          </Button>
        )}
        {!refreshState.done && (
          <Button
            onClick={handleRefresh}
            disabled={
              refreshState.processing || previewState.data == null
              // || (previewState.data.contactsToAdd === 0 && previewState.data.contactsToRemove === 0)
            }
          >
            {refreshState.processing ? 'Refreshing...' : 'Refresh'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
