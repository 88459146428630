import { InternalNavbar } from '@avenue-8/platform-style-util-frontend'
import { Box, Skeleton } from '@mui/material'
import { useHistory } from 'react-router'
import { useEmailTemplates } from '../contexts/email-templates.context'
import TemplateRow from './components/template-row'

export const GalleryModule = (): JSX.Element => {
  const history = useHistory()
  const { emailTemplates } = useEmailTemplates()

  return (
    <Box maxWidth='xl' px={6} mb={10}>
      <InternalNavbar title='Email Blast' showBackButton onBackButtonClicked={() => history.goBack()} />

      {emailTemplates === null ? (
        <Skeleton variant='rectangular' width='100%' height='40vh' />
      ) : (
        <Box>
          {emailTemplates?.map((emailTemplate) => (
            <TemplateRow
              key={emailTemplate.id}
              id={emailTemplate.id}
              title={emailTemplate.name}
              templates={emailTemplate.templates}
            />
          ))}
        </Box>
      )}
    </Box>
  )
}
