import {
  UnlayerToolValidatorData,
  ButtonDesignDataValues,
  ImageDesignDataValues,
  SocialDesignDataValues,
  VideoDesignDataValues,
} from '../../shared/utils/types'

export const defineCustomValidators = (emailEditorRef: React.MutableRefObject<any>): void => {
  if (!emailEditorRef.current) return

  emailEditorRef.current.editor.setToolValidator(
    'button',
    function (data: UnlayerToolValidatorData<ButtonDesignDataValues>) {
      const { defaultErrors, values } = data

      // We can't use any functions that was defined outside this scope.

      const isUsPhoneNumber = (value: string): boolean => {
        const usPhoneNumberRgx = /^\(?\d?[-.]?\(?(\d{3})\)?[-.]?(\d{3})[-.]?(\d{4})$/
        return !!value?.trim() && !!value.match(usPhoneNumberRgx)
      }

      const isValidEmail = (value: string): boolean => {
        return !!value?.trim() && !!value.match(/^\S+@\S+$/)
      }

      const ctaValidator: Record<
        string,
        () => UnlayerToolValidatorData<ButtonDesignDataValues>['defaultErrors'][0] | null
      > = {
        email: () => {
          if (!isValidEmail(values.href?.values?.email)) {
            return {
              id: 'CUSTOM_BUTTON_EMAIL',
              icon: 'fa-envelope',
              severity: 'ERROR',
              title: 'Button missing mail to',
              description:
                'Without a mail to, buttons will not work when a user clicks on it. Add a valid e-mail address to the following buttons.',
            }
          }
          return null
        },
        phone: () => {
          if (!isUsPhoneNumber(values.href.values.phone)) {
            return {
              id: 'CUSTOM_BUTTON_PHONE',
              icon: 'fa-phone',
              severity: 'ERROR',
              title: 'Button phones are empty',
              description:
                'Without a phone number, buttons will not work when a user clicks on it. Add a valid phone number to the following buttons.',
            }
          }
          return null
        },
      }

      const error = ctaValidator[values.href.name]?.() ?? null

      return error ? defaultErrors.concat(error) : defaultErrors
    }
  )

  emailEditorRef.current.editor.setToolValidator(
    'image',
    function (data: UnlayerToolValidatorData<ImageDesignDataValues>) {
      const { defaultErrors, values } = data

      const ERRORS_TO_IGNORE = ['IMAGE_ALT_TEXT']
      const IMAGE_HEADER_ID = 'u_content_image_1'
      const IMAGE_FOOTER_ID = 'u_content_image_5'
      const IMAGE_CONTENT_IDS_TO_INGORE = [IMAGE_HEADER_ID, IMAGE_FOOTER_ID] // WORKAROUND to ignore the validation for these images

      // We can't use any functions that was defined outside this scope.

      const isUsPhoneNumber = (value: string): boolean => {
        const usPhoneNumberRgx = /^\(?\d?[-.]?\(?(\d{3})\)?[-.]?(\d{3})[-.]?(\d{4})$/
        return !!value?.trim() && !!value.match(usPhoneNumberRgx)
      }

      const isValidEmail = (value: string): boolean => {
        return !!value?.trim() && !!value.match(/^\S+@\S+$/)
      }

      const isUrlValidFormat = (url: string): boolean => {
        const URL_REGEX = /^(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)$/
        return !!url?.trim() && !!url.match(URL_REGEX)
      }

      const ctaValidator: Record<
        string,
        () => UnlayerToolValidatorData<ImageDesignDataValues>['defaultErrors'][0] | null
      > = {
        web: () => {
          if (!isUrlValidFormat(values.action.values.href)) {
            return {
              id: 'CUSTOM_IMAGE_ACTION_LINK',
              icon: 'fa-link',
              severity: 'ERROR',
              title: 'Image links are empty',
              description:
                'Image - links are missing - Image functionality requires an action link. Add a link to the following images.',
            }
          }
          return null
        },
        email: () => {
          if (!isValidEmail(values.action.values.email)) {
            return {
              id: 'CUSTOM_IMAGE_EMAIL',
              icon: 'fa-envelope',
              severity: 'ERROR',
              title: 'Image missing mail to',
              description:
                'Image - email address missing - Image functionality requires an action link. Add email(s) in "Mail To" section to the following images.',
            }
          }
          return null
        },
        phone: () => {
          if (!isUsPhoneNumber(values.action.values.phone)) {
            return {
              id: 'CUSTOM_IMAGE_PHONE',
              icon: 'fa-phone',
              severity: 'ERROR',
              title: 'Image phones are empty',
              description:
                'Image - phone number missing - Image functionality requires an action link. Add valid phone number to the following images.',
            }
          }
          return null
        },
      }

      const shouldIgnoreImage = !IMAGE_CONTENT_IDS_TO_INGORE.includes(values._meta.htmlID)

      const errors = defaultErrors.filter((e) => !ERRORS_TO_IGNORE.includes(e.id))
      const error = shouldIgnoreImage ? ctaValidator[values.action.name]?.() ?? null : null

      return error ? errors.concat(error) : errors
    }
  )

  emailEditorRef.current.editor.setToolValidator(
    'social',
    function (data: UnlayerToolValidatorData<SocialDesignDataValues>) {
      const { defaultErrors, values } = data

      // We can't use any functions that was defined outside this scope.

      const isUrlValidFormat = (url: string): boolean => {
        const URL_REGEX = /^(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)$/
        return !!url?.trim() && !!url.match(URL_REGEX)
      }

      const hasPathname = (value: string): boolean => {
        try {
          const url = new URL(value)
          return url.pathname !== '/'
        } catch {
          return false
        }
      }

      const errors = data.values.icons.icons
        .map((icon) => {
          if (!isUrlValidFormat(icon.url) || !hasPathname(icon.url)) {
            return {
              id: 'CUSTOM_SOCIAL_LINK',
              icon: 'fa-hashtag',
              severity: 'ERROR',
              title: 'Social links are invalid',
              description:
                'Social - links are missing - Social functionality requires a valid URL. Add a link to the following socials.',
            }
          }
          return null
        })
        .filter(Boolean) as UnlayerToolValidatorData<SocialDesignDataValues>['defaultErrors']

      return defaultErrors.concat(errors)
    }
  )

  emailEditorRef.current.editor.setToolValidator(
    'video',
    function (data: UnlayerToolValidatorData<VideoDesignDataValues>) {
      const { defaultErrors, values } = data

      // We can't use any functions that was defined outside this scope.

      if (!values.video || !values.video.url) {
        return defaultErrors.concat({
          id: 'CUSTOM_VIDEO_LINK',
          icon: 'fa-video',
          severity: 'ERROR',
          title: 'Video URLs are required',
          description:
            'Video - links are missing - Video functionality requires a valid URL. Add a link to the following videos.',
        })
      }

      return defaultErrors
    }
  )
}
