import { Tooltip } from '@avenue-8/platform-style-util-frontend'
import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import { SOIList } from '../../domain/soi-list.dto'
import { TargetListDto } from '../../domain/target-lists.dto'
import { TimeOption } from '../utils/getTimeOptionsArray'
import EmailDetailsForm from './email-details-form'
import EmailListSharingForm from './email-list-sharing-form'
import EmailSharingForm from './email-sharing-form'

const FormHeader = styled.div`
  display: flex;
  align-items: center;
`

type CampaignDetailsProps = {
  data: {
    soiLists: {
      data: SOIList[]
      loading: boolean
    }
    mlsLists: {
      data: SOIList[]
      loading: boolean
    }
    targetLists: {
      data: TargetListDto[]
      loading: boolean
    }
    timeOptions: TimeOption[]
  }
}
export default function CampaignDetails({ data }: CampaignDetailsProps): JSX.Element {
  return (
    <Box>
      <FormHeader>
        <Typography color='secondary'>Enter your email details.</Typography>
      </FormHeader>

      <EmailDetailsForm timeOptions={data.timeOptions} />

      <FormHeader>
        <Typography color='secondary'>Select who you`ll be sharing this email with.</Typography>
        <Tooltip
          title='For email automation, either enter individual emails -OR- use the preselect list option below.'
          titleStyles={{
            fontWeight: 400,
            fontSize: '12px',
          }}
          placement='top-end'
        />
      </FormHeader>

      <EmailSharingForm />
      <EmailListSharingForm soiLists={data.soiLists} mlsLists={data.mlsLists} targetLists={data.targetLists} />
    </Box>
  )
}
