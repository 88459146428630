import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { BoxProps } from '@mui/system'
import { theme } from '@avenue-8/platform-style-util-frontend'

const CustomBox = styled(Box)<{ $color: string }>`
  background-color: ${(p) => (p.$color === 'grey' ? '#fafaff' : theme.palette.background.default)};
  color: ${(p) => (p.$color === 'grey' ? '#72728c' : theme.palette.primary.main)};
  border-radius: 10px;
`

interface ColoredBoxProps extends BoxProps {
  children: React.ReactNode
  color: 'grey'
}

export default function ColoredBox(props: ColoredBoxProps): JSX.Element {
  const { children, color, ...boxProps } = props
  return (
    <CustomBox $color={color} {...boxProps}>
      {children}
    </CustomBox>
  )
}
