import { AuthedFetch, ResponseType, VISUAL_MARKETING_API_URL } from '@avenue-8/platform-shared-util-frontend'
import { EmailTemplateResponseDTO } from '../../../domain/email-templates.dto'
import { EmailTemplatesService } from './email-templates.service.type'

const BASE_URL = VISUAL_MARKETING_API_URL

export class EmailTemplatesServicesImpl implements EmailTemplatesService {
  agentId: string
  token: string
  contentGridInstance: any

  constructor(agentId: string, token: string) {
    this.agentId = agentId
    this.token = token
  }

  async getTemplates(): Promise<ResponseType<EmailTemplateResponseDTO[]>> {
    try {
      const res = await AuthedFetch(`${BASE_URL}/v2/email/templates`)
      const data = (await res.json()) as any

      return {
        data,
        success: true,
      }
    } catch (error) {
      console.error(error)
      return {
        success: false,
      }
    }
  }
}
