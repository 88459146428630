import { Box, Button, IconButton, TextField as MuiTextField, Typography } from '@mui/material'
import { useMemo, useRef, useState } from 'react'
import * as yup from 'yup'
import styled from '@emotion/styled'
import { useEmailIntegrations } from '../../contexts/email-integrations.context'
import { useEmailProjects } from '../../contexts/email-projects.context'
import { useOverlayLoading } from '../../contexts/overlay-loading.context'
import { CloseIcon, InputLabel } from '@avenue-8/platform-style-util-frontend'

// Material UI's Modal should not be used so it doesn't conflict with checkout modal already open

type SendPreviewDialogProps = {
  open: boolean
  onClose: () => void
}

const Modal = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  width: 100%;
`

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Card = styled(Box)`
  background-color: white;
  padding: 2rem;
  padding-top: 1rem;
  border-radius: 4px;
`

const Actions = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`

const TextField = styled(MuiTextField)`
  width: 30rem;
`
const SendButton = styled(Button)`
  margin-top: 1.5rem;
`

const SuccessCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 2rem;
`

export const SendPreviewDialog = ({ open, onClose }: SendPreviewDialogProps): JSX.Element | null => {
  const [previewEmail, setPreviewEmail] = useState('')
  const [hasPreviewEmailBeenSent, setHasPreviewEmailBeenSent] = useState(false)

  const { currentProject: project } = useEmailProjects()
  const { sendPreview } = useEmailIntegrations()
  const { showLoading, hideLoading } = useOverlayLoading()

  const isValidEmailAddress = useMemo(() => yup.string().required().email().isValidSync(previewEmail), [previewEmail])

  const modalRef = useRef<any>(null)

  if (!open) return null

  const resetForm = () => {
    setPreviewEmail('')
  }

  const closeHandler = () => {
    setHasPreviewEmailBeenSent(false)
    onClose()
  }

  const clickHandler = async () => {
    showLoading('Sending preview...')

    await sendPreview({
      html: project.html,
      previewEmails: [previewEmail],
    })

    resetForm()
    hideLoading()
    setHasPreviewEmailBeenSent(true)
  }

  const modalClickHandler = (e) => {
    if (e.target !== modalRef?.current) return
    closeHandler()
  }

  const formCard = (
    <Card>
      <Actions>
        <IconButton onClick={closeHandler} sx={{ transform: 'translateX(10px)' }}>
          <CloseIcon />
        </IconButton>
      </Actions>
      <Content>
        <Box>
          <InputLabel>Email Address</InputLabel>
          <TextField
            variant='standard'
            type='email'
            placeholder='Enter an email'
            value={previewEmail}
            onChange={(e) => setPreviewEmail(e.target.value)}
          />
        </Box>
        <SendButton variant='contained' onClick={clickHandler} disabled={!isValidEmailAddress}>
          Send
        </SendButton>
      </Content>
    </Card>
  )

  // Card for when preview has been sent
  const successCard = (
    <SuccessCard>
      <Typography variant='h5' component='h1'>
        Your preview email has been sent.
      </Typography>
      <Button sx={{ mt: 3 }} onClick={closeHandler}>
        Ok
      </Button>
    </SuccessCard>
  )

  // Reuse custom modal to change content based whether the preview email has been sent
  return (
    <Modal onClick={modalClickHandler} ref={modalRef}>
      {hasPreviewEmailBeenSent ? successCard : formCard}
    </Modal>
  )
}
