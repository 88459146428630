import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import { Box, Button, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { useHistory, useParams } from 'react-router-dom'
import { StrictMode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import EmailEditor from 'react-email-editor'
import { ProjectHeader } from './project-header'
import { datadogLogs, useListings, useTeammates, useUserDataContext } from '@avenue-8/platform-shared-util-frontend'
import { getEmailEditorConfigOptions } from '../config/email-editor.config'
import { useEmailProjects } from '../../contexts/email-projects.context'
import { useOverlayLoading } from '../../contexts/overlay-loading.context'
import { PreviewModal } from '../../shared/components/preview.modal'
import { findCover, findHeadline } from '../../shared/utils'
import { applyTheme } from '../../email-editor/utils'
import { UnlayerDesignData } from '../../shared/utils/types'
import { defaultTheme } from '@avenue-8/platform-style-util-frontend'
import { defineCustomValidators } from '../utils/define-custom-validators'

type DesignSaveOptions = {
  saveTheme?: boolean
}

const EditorContainer = styled(Box)`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;

  &:before {
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    color: #73738c;
    letter-spacing: 2px;
    font-size: 10px;
    position: absolute;
    margin-top: 1rem;
    margin-left: 1rem;
  }
`

const BackButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    variant='text'
    startIcon={<KeyboardArrowLeft />}
    sx={{ marginTop: '11px', marginBottom: '7px' }}
    onClick={onClick}
  >
    Back to Templates
  </Button>
)

export const TemplatePage = (): JSX.Element => {
  const emailEditorRef = useRef(null)
  const params = useParams()
  const history = useHistory()

  const agent = useUserDataContext()
  const { listings } = useListings()
  const { teammates } = useTeammates()
  const { currentProject: project, loadProject, saveProject, changeProjectTitle, updateHTML } = useEmailProjects()

  useEffect(() => {
    loadProject(+params.projectId).catch(() => {
      history.replace('/gallery')
      hideLoading()
      setTimeout(() => alert('Project not found.'), 1000)
    })
  }, [])

  const [hasDesignLoaded, setHasDesignLoaded] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (project && !project.rawJSON) {
      setHasDesignLoaded(true)
    }
  }, [project])

  const { hideLoading, showLoading } = useOverlayLoading()

  useEffect(() => {
    showLoading('Loading...')
    // We want this on the first mount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isTemplateDataReady = useMemo(
    () => agent && teammates && listings && project && project.id === +params.projectId,
    [agent, listings, teammates, project]
  )

  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [numberOfInvalidContentItems, setNumberOfInvalidContentItems] = useState<number>(0)

  useEffect(() => {
    if (isTemplateDataReady && hasDesignLoaded) hideLoading()
  }, [agent, listings, teammates, isTemplateDataReady, hideLoading, hasDesignLoaded])

  const onBackButtonClick = () => {
    history.replace('/gallery')
  }

  // Email Editor Design Event Listeners
  const onDesignLoad = ({ design: loadedDesign }) => {
    setHasDesignLoaded(true)
  }

  const onDesignChange = () => {
    emailEditorRef.current.editor?.saveDesign(onDesignSave)
  }

  // TODO: Handle design save logic
  const onDesignSave = (design: UnlayerDesignData, callback?: () => void, options?: DesignSaveOptions) => {
    setIsSaving(true)

    let designData = design

    if (options?.saveTheme) {
      designData = applyTheme(design)
      emailEditorRef?.current?.editor?.loadDesign(designData)
    }

    // Headline should be the first text content
    const headline = findHeadline(designData)

    const addressLine = designData.body.rows[2].columns[0].contents[0].values.listingAddress
    const payload: any = { designData, headline, addressLine }
    if (!project.cover) {
      payload.cover = findCover(designData)
    }

    emailEditorRef.current.editor.exportHtml((data) => {
      saveProject({ ...payload, html: data.html }).then(() => {
        setIsSaving(false)
        callback?.()
        datadogLogs.logger.info(data.design)
      })
    })
  }

  const onEditorLoad = () => undefined

  const onEditorReady = () => {
    emailEditorRef?.current?.editor?.addEventListener('design:loaded', onDesignLoad)
    emailEditorRef?.current?.editor?.addEventListener('design:updated', onDesignChange)

    // It needs some time to be able to load the design (1s)
    setTimeout(() => {
      if (!project?.rawJSON) {
        emailEditorRef?.current?.editor?.saveDesign(onDesignSave, undefined, { saveTheme: true })
        return
      }

      const designData = applyTheme(project.rawJSON)
      emailEditorRef?.current?.editor?.loadDesign(designData)

      defineCustomValidators(emailEditorRef)
    }, 1000)
  }

  const goToCheckout = () => {
    emailEditorRef.current.editor?.saveDesign((designData: UnlayerDesignData) => {
      onDesignSave(designData, () => {
        history.push({ pathname: '/checkout' })
      })
    })
  }

  const showPreview = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      updateHTML(data.html).then(() => setIsPreviewOpen(true))
    })
  }

  const applyAudit = useCallback(() => {
    emailEditorRef.current.editor?.audit(function (data: { status: 'PASS' | 'FAIL'; errors: any[] }) {
      if (data.status === 'FAIL') {
        emailEditorRef.current.editor.exportHtml((designData) => {
          updateHTML(designData.html).then(() => {
            setNumberOfInvalidContentItems(data.errors.length)
            showPreview()
          })
        })
        return
      }

      setNumberOfInvalidContentItems(0)
      showPreview()
    })
  }, [showPreview])

  const hasInvalidContent = numberOfInvalidContentItems > 0

  const headline = hasInvalidContent ? (
    <Typography variant='body2' color={defaultTheme.dangerColor}>
      You have {numberOfInvalidContentItems} pending issues that require attention. Please review the{' '}
      <strong>Audit</strong> tab in the left sidebar before proceeding.
    </Typography>
  ) : (
    <Typography>Please make sure everything looks right before continuing to checkout.</Typography>
  )

  const previewTitle = hasInvalidContent ? 'Missing requirements.' : 'You’re almost done! Please review your asset.'

  const handleConfirm = hasInvalidContent
    ? undefined
    : () => {
        setIsPreviewOpen(false)
        goToCheckout()
      }

  return (
    <>
      <Box sx={{ height: '100%' }}>
        <ProjectHeader
          info={{
            title: project?.title,
            updatedAt: project?.updatedAt,
          }}
          onTitleChange={(newTitle) => changeProjectTitle(newTitle)}
          onSendButtonClick={applyAudit}
          onPreviewButtonClick={applyAudit}
          isSaving={isSaving}
        />
        <BackButton onClick={onBackButtonClick} />
        <EditorContainer>
          <StrictMode>
            {isTemplateDataReady && (
              <EmailEditor
                ref={emailEditorRef}
                onLoad={onEditorLoad}
                onReady={onEditorReady}
                minHeight={780}
                options={getEmailEditorConfigOptions({
                  templateId: project?.templateId,
                  data: { listings, agent, teammates },
                })}
              />
            )}
          </StrictMode>
        </EditorContainer>
      </Box>
      <PreviewModal
        title={previewTitle}
        headline={headline}
        closeButtonTitle='Keep Editing'
        confirmButtonTitle='Send'
        assetType='email'
        open={isPreviewOpen}
        html={project?.html}
        onClose={() => setIsPreviewOpen(false)}
        onConfirm={handleConfirm}
      />
    </>
  )
}
