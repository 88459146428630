import React from 'react'
import { Typography, Button, Dialog, DialogContent, DialogActions } from '@mui/material'
import styled from '@emotion/styled'
import { theme } from '@avenue-8/platform-style-util-frontend'

const EmailsContainer = styled.div`
  max-height: 50vh;
  overflow-y: auto;
  margin-top: 1rem;
  border: 1px solid #e0e0e0;
  padding: 0.5rem;
  user-select: none;

  & > pre {
    margin: 0;
    font-family: ${theme.typography?.body2?.fontFamily};
    font-size: 0.8rem;
    line-height: 1.4;
  }
`

/**
 * A modal that displays its list of emails.
 * Also displays the last time the list was refreshed.
 * It also has a button to refresh the list.
 * And nothing else.
 */
export function TargetedListDetailsModal(props: {
  loadEmails: () => Promise<string[]>
  open: boolean
  onClose: () => void
  lastRefresh: Date
  listName: string
}): JSX.Element {
  const { loadEmails, open, onClose, lastRefresh } = props

  const [emailsState, setEmailsState] = React.useState<{
    loading: boolean
    error: Error
    data: string[]
  }>({
    loading: true,
    error: null,
    data: [],
  })

  const handleDialogEnter = React.useCallback(async () => {
    // reset state
    setEmailsState({
      loading: true,
      error: null,
      data: [],
    })
    loadEmails()
      .then((data) => {
        setEmailsState({
          loading: false,
          error: null,
          data,
        })
      })
      .catch((error) => {
        setEmailsState({
          loading: false,
          error,
          data: [],
        })
      })
  }, [loadEmails])

  // const [refreshing, setRefreshing] = React.useState(false)

  const daysAgo = Math.floor((Date.now() - lastRefresh.getTime()) / (1000 * 60 * 60 * 24))

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll='paper'
      fullWidth
      TransitionProps={{
        unmountOnExit: true,
        onEnter: handleDialogEnter,
      }}
    >
      <DialogContent>
        <Typography variant='h6'>Targeted List: {props.listName}</Typography>
        <Typography variant='body2'>
          Number of Contacts: {emailsState.data.length ? emailsState.data.length : '-'}
        </Typography>
        <Typography variant='body2'>Last Refresh: {daysAgo} days ago</Typography>
        {(() => {
          if (emailsState.loading) {
            return (
              <Typography variant='body2'>
                <br />
                Loading list of emails...
              </Typography>
            )
          }
          if (emailsState.error) {
            return (
              <Typography color={theme.palette.error.main} variant='body2'>
                <br />
                Failed to load emails
              </Typography>
            )
          }
          return (
            <EmailsContainer>
              <pre>{emailsState.data.join('\n')}</pre>
            </EmailsContainer>
          )
        })()}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
