import { CheckboxIcon, theme } from '@avenue-8/platform-style-util-frontend'
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import ColoredBox from './colored-box'

interface MailingListBoxProps {
  boxTitle: string
  mailingList: Array<any>
  name: string
  warningNote?: JSX.Element
}

export default function MailingListBox({ boxTitle, mailingList, name, warningNote }: MailingListBoxProps): JSX.Element {
  const { control } = useFormContext()

  return (
    <ColoredBox color='grey' p='16px'>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Typography color='secondary' fontSize='14px'>
          {boxTitle}
        </Typography>

        {warningNote}
      </div>

      <Grid container>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              {mailingList.map((list, index) => (
                <Grid item lg={4} key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={list.id}
                        name={list.id}
                        size='small'
                        checked={field.value.some((existingValue) => existingValue === list.id)}
                        onBlur={field.onBlur}
                        onChange={(event, checked) => {
                          if (checked) {
                            field.onChange([...field.value, event.target.value])
                          } else {
                            field.onChange(field.value.filter((value) => value !== event.target.value))
                          }
                          field.onBlur()
                        }}
                        checkedIcon={<CheckboxIcon color={theme.palette.primary.main} backgroundColor='transparent' />}
                        icon={<CheckboxIcon color='transparent' backgroundColor='transparent' />}
                      />
                    }
                    label={
                      <Typography color='primary' fontSize='14px'>
                        {list.name}
                      </Typography>
                    }
                  />
                </Grid>
              ))}
            </>
          )}
        />
      </Grid>
    </ColoredBox>
  )
}
