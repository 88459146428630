import { FinalizeFormType } from '../../domain/finalize-form'
import { isMatch, parse } from 'date-fns'

const convertSendTime = (sendTime: Date | string): string => {
  if (!sendTime) return ''
  if (sendTime instanceof Date) return sendTime.toISOString()

  // If it's only string, it has to be in the correct format: e.g. 02:00 PM
  if (isMatch(sendTime as string, 'p')) return parse(sendTime, 'p', new Date()).toISOString()

  return ''
}

export function getFinalizeData(formData: FinalizeFormType): Record<string, any> {
  const sendTime = convertSendTime(formData.sendTime)

  return {
    subjectLine: formData.subjectLine,
    sendDate: formData.sendDate,
    sendTime,
    previewText: formData.previewText,
    emails: formData.emails,
    customList: formData.customList || false,
    mlsList: formData.mlsList || false,
    soiList: formData.soiList || false,
    targetList: formData.targetList || false,
    soiListsIds: formData.soiListsIds || [],
    mlsListsIds: formData.mlsListsIds || [],
    targetListsIds: formData.targetListsIds || [],
  }
}
