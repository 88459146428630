import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { Box, Grid, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import {
  theme,
  TemplateItemContainer,
  PaginationButton,
  useScreenSize,
  SwiperComponent,
  SwiperSlideComponent,
  SwiperCore,
} from '@avenue-8/platform-style-util-frontend'

import { GalleryHeading } from './gallery-heading'
import { EmailTemplateDTO } from '../../domain/email-templates.dto'
import { generateDataAttrs } from '@avenue-8/platform-shared-util-frontend'
import kebabCase from 'lodash.kebabcase'
import { useEmailProjects } from '../../contexts/email-projects.context'

const TemplateGalleryHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

type TemplateRowProps = {
  id: number
  title: string
  templates: EmailTemplateDTO[]
}

export default function TemplateRow({ id, title, templates }: TemplateRowProps): JSX.Element {
  const history = useHistory()
  const { newProject } = useEmailProjects()

  const { isSmallScreen } = useScreenSize()
  const [navigationInformation, setNavigationInformation] = useState<null | {
    currentSlide: number
    slidesPerView: number
    totalSnaps: number
    isPreviousDisabled: boolean
    isNextDisabled: boolean
  }>(null)

  const setUpNavigationInformation = (swiper: SwiperCore & { snapIndex: number }) => {
    const slidesPerView = swiperBreakpoints[swiper.currentBreakpoint].slidesPerView as number
    setNavigationInformation({
      isPreviousDisabled: swiper.isBeginning,
      isNextDisabled: swiper.isEnd,
      currentSlide: swiper.snapIndex + 1,
      slidesPerView,
      totalSnaps: Math.ceil(templates.length / slidesPerView),
    })
  }

  const createNewProject = async (templateId: number) => {
    const project = await newProject?.({ templateId })
    history.push(`/projects/${project.id}`)
  }

  const swiperBreakpoints = useMemo((): { [width: number]: any } => {
    const breakpoints = theme.breakpoints.values
    return {
      [breakpoints.xs]: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      [breakpoints.sm]: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      [breakpoints.md]: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
      [breakpoints.lg]: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      [breakpoints.xl]: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        speed: 600,
      },
    }
  }, [])

  const swiperOptions = {
    onInit: setUpNavigationInformation,
    onBreakpoint: setUpNavigationInformation,
    breakpoints: swiperBreakpoints,
    onSnapIndexChange: (swiper: SwiperCore & { snapIndex: number }) => {
      setNavigationInformation((prevNavigationInformation) => ({
        ...prevNavigationInformation,
        isPreviousDisabled: swiper.isBeginning,
        isNextDisabled: swiper.isEnd,
        currentSlide: swiper.snapIndex + 1,
      }))
    },
    navigation: {
      prevEl: `.previous-${id}`,
      nextEl: `.next-${id}`,
    },
  }

  return (
    <Box width='100%'>
      <TemplateGalleryHeader mb={2}>
        <GalleryHeading title={title} />
        <PaginationButton
          backDisabled={navigationInformation?.isPreviousDisabled}
          backButtonProps={{
            className: `previous-${id}`,
          }}
          nextDisabled={navigationInformation?.isNextDisabled}
          nextButtonProps={{
            className: `next-${id}`,
          }}
          middleChildren={
            <Typography variant='body1' fontWeight='normal'>
              {navigationInformation?.currentSlide}/{navigationInformation?.totalSnaps}
            </Typography>
          }
        />
      </TemplateGalleryHeader>

      <Grid
        container
        sx={{
          '& .swiper-container': {
            justifyContent: 'space-between',
            width: '100%',
          },
          mt: { xs: 1 },
          maxWidth: !isSmallScreen ? 'calc(100vw - 184px) !important;' : 'initial',
        }}
      >
        <SwiperComponent swiperOptions={swiperOptions}>
          <div>
            {templates.map((template) => {
              return (
                <SwiperSlideComponent key={template.id}>
                  <Grid
                    sx={{ mb: 3 }}
                    {...generateDataAttrs({
                      metaName: kebabCase(template.name),
                      metaAction: `create-email-from-template-${kebabCase(template.name)}`,
                    })}
                  >
                    <TemplateItemContainer
                      thumbnailUrl={template.thumbnailUrl}
                      templateName={template.name}
                      onClick={() => createNewProject(template.unlayerTemplateId)}
                      showTemplateName
                    />
                  </Grid>
                </SwiperSlideComponent>
              )
            })}
          </div>
        </SwiperComponent>
      </Grid>
    </Box>
  )
}
